import React from "react";
import BaseDirectories from "../../base directories/BaseDirectories";
import { Link } from "react-router-dom";

const Footer = () => {
  
  return (
    <footer className="p-4 bg-[#EBEBEB] sm:p-6 h-full">
      <div className="mx-auto max-w-screen-xl">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <Link to="/" className="flex items-center">
              <img
                src={`${BaseDirectories.LOGOS_DIR}/aeroconvert-logo.svg`}
                alt="Aeroconvert Logo"
              />
            </Link>
            <h2 className="mt-6 mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Address
            </h2>
            <ul className="text-gray-600 dark:text-gray-400">
              <li className="mb-4">
                <span className="font-[500]">251 Little Falls Dr. Wilmington, DE 19807</span>
              </li>
              <li>
                <span className="font-[400]">info@tradelenda.com</span>
              </li>
            </ul>
          </div>
          {/* Social Media */}
          <div className="grid grid-cols-1 gap-0 sm:gap-0 sm:grid-cols-1 mb-8">
            <div className="flex md:justify-center md:items-center justify-start">
              <Link
                to=""
                className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/In.svg`}
                  alt="Aeroconvert Logo"
                />
              </Link>
              <Link
                to=""
                className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/Insta.svg`}
                  alt="Aeroconvert Logo"
                />
              </Link>
              <Link
                to=""
                className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/Twit.svg`}
                  alt="Aeroconvert Logo"
                />
              </Link>
              <Link
                to=""
                className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/Fbook.svg`}
                  alt="Aeroconvert Logo"
                />
              </Link>
            </div>
          </div>
          {/* Links */}
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2">
            <div className="mx-auto mr-10">
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                QUICK LINK
              </h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li className="mb-4">
                  <Link to="/about-us" className="hover:underline">
                    About us
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/services" className="hover:underline">
                    Service
                  </Link>
                </li>
                <li>
                  <Link to="/" className="hover:underline">
                    Exchange
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mx-auto">
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                SUPPORT
              </h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li className="mb-4">
                  <Link to="/" className="hover:underline ">
                    Blog
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/" className="hover:underline">
                    Community
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/contact-us#faq-section" className="hover:underline">
                    FAQ
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/contact-us" className="hover:underline">
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
    
      </div>
      <div className="mx-auto max-w-screen-xl text-center">
        <p className="my-6 text-gray-500 dark:text-gray-400">
          All personal Data & Information are confidential and protected in line
          with NDPB data privacy.
        </p>
        <ul className="flex flex-wrap justify-center items-center mb-6 text-gray-900 dark:text-white">
          <li>
            <Link to="" className="mr-4 hover:underline md:mr-6 ">
              Terms & Conditions
            </Link>
          </li>
          <li>
            <Link to="" className="mr-4 hover:underline md:mr-6">
              Privacy policy
            </Link>
          </li>
          <li>
            <Link to="" className="mr-4 hover:underline md:mr-6 ">
              Cookies
            </Link>
          </li>
        </ul>
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © {new Date().getFullYear()} All rights reserved. Aeroconvert by Trade
          Lenda
        </span>
      </div>
    </footer>
  );
};

export default Footer;
