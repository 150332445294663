/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import BaseDirectories from "../../base directories/BaseDirectories";
import ResponsiveTable from "../../components/ui/ResponsiveTable";
import { Button as Btn, Tabs } from "flowbite-react";
import type { CustomFlowbiteTheme, theme } from "flowbite-react";
import Button from "../../components/ui/Button";
import Select from "react-select";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../mobx stores/RootStore";
import Spinner from "../../components/Spinner";
import { runInAction, toJS } from "mobx";
import { Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../styles/slide.css";
import OtpInput from "react-otp-input";

const options = [
  {
    value: "USD",
    label: "USD - US Dollar",
    imgSrc: "../../assets/icons/usa.png",
  },
  { value: "EUR", label: "EUR - Euro", imgSrc: "../../assets/icons/eur.png" },
  {
    value: "GBP",
    label: "GBP - Bristish Pound",
    imgSrc: "../../assets/icons/uk.png",
  },
  {
    value: "CAD",
    label: "CAD - Canadian Dollar",
    imgSrc: "../../assets/icons/cad.png",
  },
  // {
  //   value: "AUD",
  //   label: "AUD - Australian Dollar",
  //   imgSrc: "../../assets/icons/aud.png",
  // },
  // {
  //   value: "JPY",
  //   label: "JPY - Japanese Yen",
  //   imgSrc: "../../assets/icons/jpy.png",
  // },
  // {
  //   value: "INR",
  //   label: "INR - Indian Rupee",
  //   imgSrc: "../../assets/icons/inr.png",
  // },
  {
    value: "NGN",
    label: "NGN - Nigerian Naira",
    imgSrc: "../../assets/icons/naira.png",
  },
];

let bankTransferDetails = {
  bank: "",
  accountName:"",
  accountNumber:"",
  accountType:"",
};

const Overview = () => {
  const [token, setToken] = useState(sessionStorage.getItem("accessToken"));
  const { walletStore, profileStore } = useContext(StoreContext);
  const {
    wallet,
    loadingWallet,
    currencyData,
    walletAccountDetails,
    submitting,
    message,
    banks,
    bankReceiverFullName,
    verifyingData,
  } = walletStore;
  const { profile } = profileStore;
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openPromptModal, setPromptOpenModal] = useState(false);
  const [openTransferPromptModal, setTransferPromptOpenModal] = useState(false);
  const [openTransferModal, setTransferOpenModal] = useState(false);
  const [pin, setPin] = useState("");
  const [pinToggle, setPinToggle] = useState(false);
  const [currency, setCurrency] = useState([
    {
      code: "NGN",
      currency: "naira",
      buy: 1,
      sell: 1,
      imgSrc: "../../assets/icons/naira.png",
    },
    {
      code: "USD",
      currency: "usd",
      buy: 1,
      sell: 1,
      imgSrc: "../../assets/icons/usa.png",
    },
    {
      code: "EUR",
      currency: "euro",
      buy: 1,
      sell: 1,
      imgSrc: "../../assets/icons/eur.png",
    },
    {
      code: "GBP",
      currency: "gbp",
      buy: 1,
      sell: 1,
      imgSrc: "../../assets/icons/uk.png",
    },
    {
      code: "CAD",
      currency: "cad",
      buy: 1,
      sell: 1,
      imgSrc: "../../assets/icons/cad.png",
    },
    // { code: "AUD", currency:"aud", buy: 1, sell: 1, imgSrc: "../../assets/icons/aud.png" },
    // { code: "INR", currency:"inr", buy: 1, sell: 1, imgSrc: "../../assets/icons/inr.png" },
    // { code: "JPY", currency:"jpy", buy: 1, sell: 1, imgSrc: "../../assets/icons/jpy.png" },
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [startX, setStartX] = useState(0);
  const galleryWrapperRef = useRef<HTMLDivElement>(null);

  const [exchangeAmount, setExchangeAmount] = useState({
    fromCurrency: "NGN",
    toCurrency: "USD",
    amount: 0,
    exchangeAmount: 0,
    conversionRate: 0,
    transactionPin: "",
  });

  const [selectedOption, setSelectedOption] = useState();

  const [transferDetails, setTransferDetails] = useState({
    receiverAccountFirstName: "",
    receiverAccountLastName: "",
    receiverAccountNumber: "",
    receiverBankName: "",
    amount: "",
    narration: "Withdrawal from aeroconvert wallet",
    saveBeneficiary: false,
    transactionPin: "",
  });


  useEffect(() => {
      walletStore.getWallet();
      walletStore.setWallet;
      walletStore.setWalletAccountDetails;
      walletStore.getCurrency();
      walletStore.getAllTransactions();
      walletStore.getNipBanks();
  }, []);

  // get wallet data at intervals
  useEffect(() => {
    const interval = setInterval(() => {
      if (!loadingWallet) {
        if (token) {
          walletStore.getWalletWithoutLoading();
          walletStore.getCurrency();
        } else {
          setToken(sessionStorage.getItem("accessToken"));
        }
      }
    }, 300000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data: any = toJS(currencyData)[0];
    if (data !== undefined || data != null) {
      setCurrency([
        {
          code: "NGN",
          currency: "naira",
          buy: 1,
          sell: 1,
          imgSrc: "../../assets/icons/naira.png",
        },
        {
          code: "USD",
          currency: "usd",
          buy: data["USD"][0]?.buy,
          sell: data["USD"][0]?.buy,
          imgSrc: "../../assets/icons/usa.png",
        },

        {
          code: "EUR",
          currency: "euro",
          buy: data["EUR"][0]?.buy,
          sell: data["EUR"][0]?.buy,
          imgSrc: "../../assets/icons/eur.png",
        },
        {
          code: "GBP",
          currency: "gbp",
          buy: data["GBP"][0]?.buy,
          sell: data["GBP"][0]?.buy,
          imgSrc: "../../assets/icons/uk.png",
        },
        {
          code: "CAD",
          currency: "cad",
          buy: data["CAD"][0]?.buy,
          sell: data["CAD"][0]?.buy,
          imgSrc: "../../assets/icons/cad.png",
        },
        // { code: "AUD",  currency: "aud", buy: 1, sell: 1, imgSrc: "../../assets/icons/aud.png" },
        // { code: "INR",  currency: "inr", buy: 1, sell: 1, imgSrc: "../../assets/icons/inr.png" },
        // { code: "JPY",  currency: "jpy", buy: 1, sell: 1, imgSrc: "../../assets/icons/jpy.png" },
      ]);
    }
  }, [walletStore, wallet, currencyData]);

 bankTransferDetails = {
    bank: "Providus Bank",
    accountName:
      toJS(walletAccountDetails)?.firstName !== undefined
        ? toJS(walletAccountDetails)?.firstName +
          " " +
          toJS(walletAccountDetails)?.lastName
        : "NIL",
    accountNumber:
      toJS(wallet)?.walletIdAccountNumber !== undefined
        ? toJS(wallet)?.walletIdAccountNumber
        : "NIL",
    accountType:
      wallet?.type && toJS(wallet)?.type !== "Personal"
        ? "Coporate Account"
        : "Individual Account",
  };

  const handleSelectChange = (selectedOption: any) => {
    setExchangeAmount({
      fromCurrency: "NGN",
      toCurrency: "USD",
      amount: 0,
      exchangeAmount: 0,
      conversionRate: 0,
      transactionPin: "",
    });
    setExchangeAmount((prevData) => ({
      ...prevData,
      toCurrency: selectedOption.value,
    }));
  };

  // Input change listener for Buy
  const handleBuyAmountChange = (e: React.FormEvent) => {
    const element = e.target as HTMLInputElement;
    setExchangeAmount((prevDetails: any) => {
      return {
        ...prevDetails,
        amount: Number(element.value),
      };
    });

    const convert =
      Number(element.value) /
      Number(
        currency.find((d: any) => d.code === exchangeAmount.toCurrency)?.buy
      );

    setExchangeAmount((prevDetails: any) => {
      return {
        ...prevDetails,
        exchangeAmount: Number(convert),
      };
    });
  };
  // Input change listener for Sell
  const handleSellAmountChange = (e: React.FormEvent) => {
    const element = e.target as HTMLInputElement;
    setExchangeAmount((prevDetails: any) => {
      return {
        ...prevDetails,
        amount: Number(element.value),
      };
    });

    const convert =
      Number(element.value) *
      Number(
        currency.find((d: any) => d.code === exchangeAmount.toCurrency)?.buy
      );

    setExchangeAmount((prevDetails: any) => {
      return {
        ...prevDetails,
        exchangeAmount: Number(convert),
      };
    });
  };

  // Button finction to show prompt modal for buy
  const handleSubmitBuyCurrency = (e: React.FormEvent) => {
    e.preventDefault();
    const convert =
      Number(exchangeAmount.amount) /
      Number(
        currency.find((d: any) => d.code === exchangeAmount.toCurrency)?.buy
      );

    setExchangeAmount((prevDetails: any) => {
      return {
        ...prevDetails,
        exchangeAmount: Number(convert),
      };
    });

    setPin("");
    setPromptOpenModal(true);
  };
  // Button finction to show prompt modal for sell
  const handleSubmitSellCurrency = (e: React.FormEvent) => {
    e.preventDefault();
    const convert =
      Number(exchangeAmount.amount) *
      Number(
        currency.find((d: any) => d.code === exchangeAmount.toCurrency)?.sell
      );
    setExchangeAmount({
      ...exchangeAmount,
      exchangeAmount: Number(convert),
    });
    setPin("");
    setPromptOpenModal(true);
  };

  const processBuyCurrency = () => {
    if (exchangeAmount.transactionPin.length < 4) {
      toast.error("Please check the pin and try again.");
      return;
    }
    if (
      Number(toJS(wallet.availableBalance)) >= Number(exchangeAmount.amount)
    ) {
      const payLoad = {
        walletId: bankTransferDetails.accountNumber,
        transactionType: "Buy",
        fromCurrency: exchangeAmount.fromCurrency,
        toCurrency: exchangeAmount.toCurrency,
        fromAmount: Number(exchangeAmount.amount),
        toAmount: Number(exchangeAmount.exchangeAmount),
        conversionRate: Number(
          currency.find((d: any) => d.code === exchangeAmount.toCurrency)?.buy
        ),
        transactionPin: exchangeAmount.transactionPin,
      };
      walletStore.convertCurrency(payLoad);
      setPromptOpenModal(false);
    } else {
      toast.error("You do not have enough funds!");
    }
  };

  const customTheme: CustomFlowbiteTheme["tabs"] = {
    base: "flex flex-col gap-2",
    tablist: {
      base: "flex text-center",
      styles: {
        fullWidth: "w-full text-sm font-medium grid grid-flow-col rounded-none",
      },
      tabitem: {
        base: "flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500",
        styles: {
          fullWidth: {
            base: "ml-0 first:ml-0 w-full h-full rounded-none flex",
            active: {
              on: "p-4 text-[#fff] font-[500] md:text-[24px] text-md bg-[#054B98] active rounded-none",
              off: "text-[#054B98] md:text-[24px] text-md font-[500] bg-white rounded-none",
            },
          },
        },
      },
    },
    tabitemcontainer: {
      base: "",
      styles: {
        default: "",
        underline: "",
        pills: "",
        fullWidth: "",
      },
    },
    tabpanel: "py-3",
  };

  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      font: "14px Montserrat",
      fontWidth: "400",
    }),
    control: (provided: any) => ({
      ...provided,
      width: "100%",
      font: "14px Montserrat",
      fontWidth: "400",
      height: "45px",
    }),
  };

  const CustomSingleValue = ({ innerProps, data }: any) => (
    <div
      {...innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        height: "45px",
        gap: "4px",
        position: "absolute",
        paddingLeft: "10px",
      }}
    >
      {data.label}
    </div>
  );

  useEffect(() => {
    if (message.type === "success" && message.msg === "Transfer successful!") {
      setExchangeAmount({
        fromCurrency: "NGN",
        toCurrency: "USD",
        amount: 0,
        exchangeAmount: 0,
        conversionRate: 0,
        transactionPin: "",
      });
      profileStore.getProfile();
      walletStore.getWallet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.msg, message.type]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % currency.length);
  };

  const prevSlide = () => {
    if (currentIndex !== 0) {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + currency.length) % currency.length
      );
    }
  };

  const calculateTranslation = () => {
    let itemWidth = 450;

    if (windowWidth <= 320) {
      itemWidth = 1480 / currency.length;
    } else if (windowWidth > 320 && windowWidth <= 375) {
      itemWidth = 1760 / currency.length;
    } else if (windowWidth > 424 && windowWidth <= 767) {
      itemWidth = 1020 / currency.length;
    } else if (windowWidth > 767 && windowWidth <= 1023) {
      itemWidth = 960 / currency.length;
    } else if (windowWidth > 1023) {
      itemWidth = 450 / currency.length;
    }
    return -currentIndex * itemWidth;
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNipAccountVerification = () => {
    if (
      !transferDetails.receiverAccountNumber ||
      !transferDetails.receiverBankName
    ) {
      toast.error(
        "Please make sure bank and account number are filled to verify account name"
      );
      return;
    }
    if (
      transferDetails.receiverAccountNumber &&
      transferDetails.receiverBankName &&
      bankReceiverFullName.length === 0
    ) {
      walletStore.checkNipAccount(
        transferDetails.receiverAccountNumber,
        transferDetails.receiverBankName
      );
    }
  };

  useEffect(() => {
    const splitName = bankReceiverFullName?.split(/\s+/);
    const firstName = splitName[0]?.trim();
    const lastName =!splitName[2] ? splitName[1]?.trim() : splitName[1]?.trim() +" "+splitName[2]?.trim();

    setTransferDetails((prevDetails: any) => {
      return {
        ...prevDetails,
        receiverAccountFirstName: firstName,
        receiverAccountLastName: lastName,
      };
    });
  }, [bankReceiverFullName]);

  const handleTransferInputChange = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    const val =
      element.name === "amount"
        ? element.value.length === 0
          ? element.value
          : parseFloat(element.value)
        : element.value;

    setTransferDetails((prevDetails: any) => {
      return { ...prevDetails, [element.name]: val };
    });
  };

  const validate = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /^[0-9]{0,9}$/;
    // const regex = /[0-9]/;
    if (
      !regex.test(transferDetails.receiverAccountNumber) ||
      !regex.test(key)
    ) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const handleCloseTransferModal = () => {
    setTransferDetails({
      receiverAccountFirstName: "",
      receiverAccountLastName: "",
      receiverAccountNumber: "",
      receiverBankName: "",
      amount: "",
      narration: "Withdrawal from aeroconvert wallet",
      saveBeneficiary: false,
      transactionPin: "0000",
    });

    setTransferOpenModal(false);
  };

  useEffect(() => {
    if (transferDetails.receiverAccountNumber.length === 10) {
      handleNipAccountVerification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferDetails.receiverAccountNumber]);

  useEffect(() => {
    setTransferDetails((prevDetails: any) => {
      return { ...prevDetails, transactionPin: pin };
    });

    setExchangeAmount((prevDetails: any) => {
      return { ...prevDetails, transactionPin: pin };
    });
  }, [pin]);

  const handlePinToggle = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    setPinToggle(element.checked);
  };

  const validateNumber = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const disableIt =
    !transferDetails.receiverBankName ||
    Number(transferDetails.amount) < 100 ||
    String(transferDetails.receiverAccountNumber).length < 10 ||
    bankReceiverFullName.length < 1;

  const disableProceedIt =
    !transferDetails.receiverBankName ||
    Number(transferDetails.amount) < 100 ||
    String(transferDetails.receiverAccountNumber).length < 10 ||
    bankReceiverFullName.length < 1 ||
    pin.length < 4;

  const handleTransferTobank = (e: FormEvent) => {
    e.preventDefault();
    if (transferDetails.transactionPin.length < 4) {
      toast.error("Please check the pin and try again.");
      return;
    }
    console.log(transferDetails);
    walletStore.withdrawToBank(transferDetails);
  };

  useEffect(() => {
    if (message.msg === "Transfer successful!" && message.type === "success") {
      setTransferPromptOpenModal(false);
      handleCloseTransferModal();
      walletStore.getWallet();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.msg, message.type]);

  const isBeginning = currentIndex === 0;

  return (
    <div className="p-4 sm:ml-64 bg-[#D9DBE9]">
      {loadingWallet && <Spinner />}
      {submitting && <Spinner />}
      {verifyingData && <Spinner />}
      {/* Account Details */}
      <Modal
        show={openModal}
        onClose={() => setOpenModal(false)}
        size="md"
        popup
        position="center"
      >
        <Modal.Header>
          <div
            className="flex flex-row justify-center items-center gap-3"
            style={{}}
          >
            <img
              src={`${BaseDirectories.ICONS_DIR}/naira.png`}
              alt="ng"
              className="w-[40px] h-[40px] rounded-full p-1"
            />
            <p className="w-full text-bold text-centerfont-[700]">
              Bank Transfer{" "}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="p-1 md:p-1">
            <p className="text-sm font-normal text-gray-500 dark:text-gray-400 text-center">
              Transfer money to the wallet id below to fund your wallet
            </p>
            <ul className="my-2 space-y-1.5">
              <li className="flex items-center p-1 md:p-3 md:text-base text-sm font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                <span className="flex-1 ms-3 whitespace-nowrap justify-start">
                  Bank
                </span>
                <span className="inline-flex justify-end text-right items-end px-2 py-0.5 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">
                  {bankTransferDetails.bank}
                </span>
              </li>

              <li className="flex items-center p-1 md:p-3 md:text-base text-sm font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Account Name
                </span>
                <span
                  style={{ textTransform: "capitalize" }}
                  className="inline-flex justify-end text-right items-end px-2 py-0.5 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400"
                >
                  {bankTransferDetails.accountName}
                </span>
              </li>

              <li className="flex items-center p-1 md:p-3 md:text-base text-sm font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                <span className="flex-1 ms-3 whitespace-nowrap">Wallet ID</span>
                <span className="inline-flex justify-end text-right items-end px-2 py-0.5 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">
                  {bankTransferDetails.accountNumber}
                </span>
              </li>

              <li className="flex items-center p-1 md:p-3 md:text-base text-sm font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Account Type
                </span>
                <span className="inline-flex justify-end text-right items-end px-2 py-0.5 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">
                  {bankTransferDetails.accountType}
                </span>
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Btn
            className="text-white bg-[#054B98] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-[40px] text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 max-w-[180px] max-h-[57px] md:w-[180px] md:h-[57px] font-[700]"
            onClick={() => setOpenModal(false)}
          >
            Close
          </Btn>
        </Modal.Footer>
      </Modal>

      {/* Prompt Modal */}
      <Modal
        show={openPromptModal}
        size="md"
        onClose={() => setPromptOpenModal(false)}
        popup
        position="center"
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />

            <h3 className="mb-5 text-lg font-semibold text-gray-800">
              Transaction Summary
            </h3>
            <ul className="my-2 space-y-1.5">
              <li className="flex items-center p-1 md:p-3 md:text-base text-sm font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                <span className="flex-1 justify-start text-left ms-3 whitespace-nowrap">
                  From {exchangeAmount.fromCurrency}
                </span>
                <span className="inline-flex justify-end text-right ms-3 whitespace-nowrap text-sm font-notral text-gray-500">
                  {exchangeAmount.amount}
                </span>
              </li>
              <li className="flex items-center p-1 md:p-3 md:text-base text-sm font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                <span className="flex-1 justify-start text-left ms-3 whitespace-nowrap">
                  To {exchangeAmount.toCurrency}
                </span>
                <span className="inline-flex justify-end text-right ms-3 whitespace-nowrap text-sm font-notral text-gray-500">
                  {Number(exchangeAmount.exchangeAmount).toFixed(6)}
                </span>
              </li>
            </ul>
            <div className="input-pin">
              <h4 className="mb-3">Input pin to proceed</h4>
              <div className="row">
                <div className="col-md-12 input-box">
                  <div>
                    <OtpInput
                      value={pin}
                      onChange={setPin}
                      numInputs={4}
                      inputStyle="pin-style"
                      containerStyle="pin-container-style"
                      inputType={pinToggle ? "number" : "password"}
                      shouldAutoFocus={true}
                      renderInput={(props) => (
                        <input onKeyPress={validateNumber} {...props} />
                      )}
                    />
                  </div>
                  <div className="flex gap-2 justify-center items-center mb-3">
                    <input
                      type="checkbox"
                      onChange={handlePinToggle}
                      className="btn-switch"
                      name="showPin"
                      id="show-pin"
                    />
                    <label htmlFor="show-pin">Show pin</label>
                  </div>
                  <p style={{ fontSize: "14px" }}>
                    Don't have a pin yet? Create one{" "}
                    <Link
                      style={{ color: "blue" }}
                      to="/dashboard/settings"
                    >
                      here
                    </Link>{" "}
                  </p>
                </div>
              </div>
            </div>
            <h3 className="mt-2 mb-5 text-sm font-semibold text-red-500">
              Are you sure you want to proceed with this transaction?
            </h3>
            <div className="flex justify-center gap-4">
              <Btn
                color="failure"
                onClick={() => processBuyCurrency()}
                disabled={pin.length < 4}
              >
                {"Yes, I'm sure"}
              </Btn>
              <Btn color="gray" onClick={() => setPromptOpenModal(false)}>
                No, cancel
              </Btn>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Transfer modal */}

      <Modal
        show={openTransferModal}
        onClose={handleCloseTransferModal}
        size="md"
        popup
        position="center"
      >
        <Modal.Header>
          <div
            className="flex flex-row justify-center items-center gap-3"
            style={{}}
          >
            <img
              src={`${BaseDirectories.ICONS_DIR}/naira.png`}
              alt="ng"
              className="w-[40px] h-[40px] rounded-full p-1"
            />
            <p className="w-full text-bold text-centerfont-[700]">
              Withdraw Funds{" "}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className=" overflow-clip">
          <div className="p-1 md:p-1">
            <p className="text-sm font-normal text-gray-500 text-center">
              Transfer money to your external bank account.
            </p>
            <form
              action=""
              className="mt-4 md:mt-6"
              onSubmit={() => {
                if (Number(transferDetails.amount) < 100) {
                  toast.error("Amount must be greater than ₦100");
                } else if (
                  Number(transferDetails.amount) >
                  Number(toJS(wallet.availableBalance))
                ) {
                  toast.error("Amount is greater than wallet balance.");
                } else {
                  setTransferPromptOpenModal(true);
                }
              }}
            >
              <div className="grid gap-4 mb-3 sm:grid-cols-1">
                <div>
                  <label
                    htmlFor="amount"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Amount
                    <span
                      className="text-[#FF4B00] text-xs"
                      style={{
                        float: "right",
                      }}
                    >
                      Minimum ₦100
                    </span>
                  </label>
                  <input
                    type="number"
                    name="amount"
                    id="amount"
                    onChange={handleTransferInputChange}
                    className="input-control"
                    placeholder="Enter withdrawal amount"
                    value={transferDetails.amount}
                    required
                  />
                  <span className="text-xs">
                    <h4
                      className="text-xs"
                      style={{
                        float: "right",
                      }}
                    >
                      Wallet Balance: ₦{" "}
                      {toJS(wallet)?.availableBalance
                        ? new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(toJS(wallet)?.availableBalance)
                        : "0.00"}
                    </h4>
                  </span>
                </div>
                {transferDetails.amount &&
                  Number(transferDetails.amount) >= 100 && (
                    <div>
                      <label
                        htmlFor="receiverBankName"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Bank
                      </label>
                      <input
                        list="banks"
                        className="input-control"
                        onChange={handleTransferInputChange}
                        name="receiverBankName"
                      />
                      <datalist id="banks">
                        {toJS(banks).length > 0 &&
                          toJS(banks).map(({ bankName, NIPCode }, index) => (
                            <option key={index} value={bankName} />
                          ))}
                      </datalist>
                    </div>
                  )}
                {transferDetails.receiverBankName &&
                  transferDetails.receiverBankName.length > 0 && (
                    <div>
                      <label
                        htmlFor="receiverAccountNumber"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Account Number
                      </label>
                      <input
                        type="number"
                        onKeyPress={validate}
                        maxLength={10}
                        // onPaste={validate}
                        autoComplete="off"
                        onChange={(e: ChangeEvent) => {
                          handleTransferInputChange(e);
                          runInAction(() => {
                            walletStore.bankReceiverFullName = "";
                          });
                        }}
                        name="receiverAccountNumber"
                        id="receiverAccountNumber"
                        className="input-control"
                        placeholder="Enter account number"
                        value={
                          transferDetails.receiverAccountNumber &&
                          Number(transferDetails.receiverAccountNumber)
                        }
                        required
                      />
                    </div>
                  )}
                {transferDetails.receiverAccountNumber &&
                  transferDetails.receiverAccountNumber.length >= 10 && (
                    <div>
                      <label
                        htmlFor="accountName"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Account Name
                      </label>
                      <input
                        // onFocus={handleNipAccountVerification}
                        // onChange={null}
                        type="text"
                        name="accountName"
                        id="accountName"
                        className="input-control"
                        value={bankReceiverFullName ? bankReceiverFullName : ""}
                        readOnly={true}
                      />
                    </div>
                  )}
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className="h-16 flex justify-between">
          <Btn
            size="sm"
            outline
            color="failure"
            onClick={handleCloseTransferModal}
          >
            Close
          </Btn>
          <Btn
            size="sm"
            outline
            color="blue"
            type="button"
            disabled={disableIt}
            onClick={() => {
              if (Number(transferDetails.amount) < 100) {
                toast.error("Amount must be greater than ₦100");
              } else if (
                Number(transferDetails.amount) >
                Number(toJS(wallet.availableBalance))
              ) {
                toast.error("Amount is greater than wallet balance.");
              } else {
                setTransferPromptOpenModal(true);
              }
            }}
          >
            Proceed
          </Btn>
        </Modal.Footer>
      </Modal>

      {/* Transfer Prompt Modal */}

      <Modal
        show={openTransferPromptModal}
        size="sm"
        onClose={() => setTransferPromptOpenModal(false)}
        popup
        position="center"
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />

            <h3 className="mb-5 text-lg font-semibold text-gray-800">
              Withdrawal Summary
            </h3>
            <ul className="my-2 space-y-1.5">
              <li className="flex items-center p-1 md:p-1 text-wrap trencate overflow-hidden text-sm font-normal break-all text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                <span className="flex-1 justify-start text-left ms-3 whitespace-nowrap">
                  From : {toJS(profile?.data)?.firstName}{" "}
                  {toJS(profile?.data)?.lastName}
                </span>
              </li>
              <li className="flex items-center p-1 md:p-1 text-wrap trencate overflow-hidden text-sm font-normal break-all text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                <span className="flex-1 justify-start text-left ms-3 whitespace-nowrap">
                  To : {transferDetails.receiverAccountFirstName}{" "}
                  {transferDetails.receiverAccountLastName}
                </span>
              </li>
              <li className="flex items-center p-1 md:p-1 text-wrap trencate overflow-hidden text-sm font-normal break-all text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                <span className="flex-1 justify-start text-left ms-3 whitespace-nowrap">
                  Bank : {transferDetails.receiverBankName}
                </span>
              </li>
              <li className="flex items-center p-1 md:p-1 text-wrap trencate overflow-hidden text-sm font-normal break-all text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                <span className="flex-1 justify-start text-left ms-3 whitespace-nowrap">
                  Amount :{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(Number(transferDetails.amount))}
                </span>
              </li>
            </ul>
            <div className="input-pin">
              <h4 className="mb-3">Input pin to proceed</h4>
              <div className="row">
                <div className="col-md-12 input-box">
                  <div>
                    <OtpInput
                      value={pin}
                      onChange={setPin}
                      numInputs={4}
                      inputStyle="pin-style"
                      containerStyle="pin-container-style"
                      inputType={pinToggle ? "tel" : "password"}
                      shouldAutoFocus={true}
                      renderInput={(props) => (
                        <input onKeyPress={validateNumber} {...props} />
                      )}
                    />
                  </div>
                  <div className="flex gap-2 justify-center items-center mb-3">
                    <input
                      type="checkbox"
                      onChange={handlePinToggle}
                      className="btn-switch"
                      name="showPin"
                      id="show-pin"
                    />
                    <label htmlFor="show-pin">Show pin</label>
                  </div>
                  <p style={{ fontSize: "14px" }}>
                    Don't have a pin yet? Create one{" "}
                    <Link
                      style={{ color: "blue" }}
                      to="/dashboard/settings"
                    >
                      here
                    </Link>{" "}
                  </p>
                </div>
              </div>
            </div>
            <h3 className="mt-2 mb-5 text-sm font-semibold text-red-500">
              Are you sure you want to proceed with this transaction?
            </h3>
            <div className="flex justify-center gap-4">
              <Btn
                color="failure"
                onClick={(e: FormEvent) => handleTransferTobank(e)}
                disabled={disableProceedIt}
              >
                {"Yes, I'm sure"}
              </Btn>
              <Btn
                color="gray"
                onClick={() => setTransferPromptOpenModal(false)}
              >
                No, cancel
              </Btn>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="p-0 md:p-4 bg-[#D9DBE9] rounded-lg">
        {/* first section */}
        <div className="flex flex-col lg:flex-row gap-2 w-full">
          <div
            className="gallery-container relative flex-1"
            // onTouchStart={handleTouchStart}
            // onTouchMove={handleTouchMove}
          >
            <div
              className="gallery-wrapper"
              style={{ transform: `translateX(${calculateTranslation()}px)` }}
              ref={galleryWrapperRef}
            >
              {currency.map((data: any, index: number) => (
                <div key={index} className="gallery-item">
                  <div className="flex items-center justify-center h-44 rounded bg-gray-50 ">
                    <div className="flex flex-wrap items-center justify-center gap-0">
                      <img
                        src={data.imgSrc}
                        alt={`${data.code}`}
                        className="w-[70px] h-[70px] rounded-full"
                      />
                      <div className="flex flex-col px-4 box-border break-all break-words">
                        <p className="text-[#000] font-mont text-lg font-medium">
                          {data.code}
                        </p>
                        <p className="text-[#000] font-mont text-[15px] font-medium ">
                          {data.currency === "naira"
                            ?
                              toJS(wallet)?.availableBalance !== undefined
                              ? wallet?.availableBalance !== "NaN" ||
                                wallet?.availableBalance !== null ||
                                wallet?.availableBalance !== undefined
                                ? new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits:
                                      Number(wallet.availableBalance) === 0
                                        ? 2
                                        : 0,
                                    maximumFractionDigits:
                                      Number(wallet.availableBalance) === 0
                                        ? 2
                                        : 0,
                                  }).format(
                                    Number(toJS(wallet).availableBalance)
                                  )
                                : "0.00"
                              : "0.00"
                            : toJS(profile)?.data !== undefined
                            ? toJS(profile)?.data[`${data.currency}`] !==
                                "NaN" ||
                              toJS(profile)?.data[`${data.currency}`] !== null
                              ? new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits:
                                    toJS(profile)?.data[`${data.currency}`] ===
                                    0
                                      ? 2
                                      : 3,
                                  maximumFractionDigits:
                                    toJS(profile)?.data[`${data.currency}`] ===
                                    0
                                      ? 2
                                      : 3,
                                }).format(
                                  Number(
                                    toJS(profile)?.data[`${data.currency}`]
                                  )
                                )
                              : "0.00"
                            : "0.00"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button
              className="next-button"
              onClick={nextSlide}
              // disabled={isEnd}
            >
              <img
                src={`${BaseDirectories.ICONS_DIR}/forward.png`}
                alt="ng"
                className="h-8 w-8"
              />
            </button>

            <button
              className="prev-button"
              onClick={prevSlide}
              disabled={isBeginning}
            >
              <img
                src={`${BaseDirectories.ICONS_DIR}/forward.png`}
                alt="ng"
                className="h-8 w-8"
              />
            </button>
          </div>
          <div className="flex items-center justify-center lg:h-44 lg:w-20 mb-4 mt-2 h-12 lg:mb-0 lg:mt-0 rounded bg-gray-50 ">
            <img
              src={`${BaseDirectories.ICONS_DIR}/add.png`}
              alt="ng"
              className="h-9 w-9"
            />
          </div>
        </div>
        {/* Second Section */}
        <div className="grid xl:grid-rows-8 lg:grid-rows-12 md:grid-rows-5 grid-rows-5 grid-flow-col gap-4 mb-4">
          <section className="flex gap-4 flex-col lg:flex-row justify-evenly row-span-1 col-span-3 col-start-1 xl:col-span-2 xl:col-start-1 xl:row-span-1 lg:col-span-2 lg:col-start-1 lg:row-span-1 md:col-span-6 md:col-start-1 md:row-span-1 w-full">
            <button
              className="w-full h-[56px] bg-[#054B98] text-[#FFF] font-mont text-lg font-bold shadow-md hover:bg-[#244263]"
              onClick={() => navigate("/dashboard/wallet")}
            >
              Make payment
            </button>

            <button
              className="w-full h-[56px] bg-[#fff] text-[#054B98] font-mont text-lg font-bold shadow-md hover:bg-[#c9cccf]"
              onClick={() => setOpenModal(true)}
            >
              Add funds
            </button>

            <button
              className="w-full h-[56px] bg-[#fff] text-[#054B98] font-mont text-lg font-bold shadow-md hover:bg-[#c9cccf]"
              onClick={() => setTransferOpenModal(true)}
            >
              Withdraw funds
            </button>
          </section>
          {/* converter calculator */}
          <div className="rounded bg-[#054B98] dark:bg-gray-800 xl:col-start-3 xl:col-span-1 xl:row-span-8 lg:col-start-1 lg:col-span-2 lg:row-span-6 md:col-start-1 md:col-span-6 md:row-span-2 row-span-2 col-span-3 col-start-1">
            <div className="">
              <Tabs
                aria-label="Full width tabs"
                className=""
                // eslint-disable-next-line react/style-prop-object
                style="fullWidth"
                theme={customTheme}
              >
                <Tabs.Item
                  active
                  title="BUY"
                  className="inline-block p-4 border-b-2 rounded-t-lg text-[#054B98] text-[24px]"
                >
                  {/* Buy Form */}
                  <form
                    className="flex flex-col gap-6 mx-4 md:mt-14 mt-8"
                    action=""
                    onSubmit={handleSubmitBuyCurrency}
                  >
                    <div>
                      <p className="input-label text-white mb-5">
                        Enter your destination country / currency:
                      </p>
                      <Select
                        options={options}
                        styles={customStyles}
                        name="currency"
                        value={selectedOption}
                        onChange={handleSelectChange}
                        placeholder="Select Currency"
                        isSearchable={false}
                        components={{
                          Option: ({ innerProps, label, data }) => (
                            <div
                              {...innerProps}
                              className="flex flex-row px-auto mx-auto gap-8 text-[14px] mb-3 mt-2 cursor-pointer"
                            >
                              <img
                                src={data?.imgSrc}
                                alt={label}
                                className="h-5 w-6 my-auto ml-4"
                              />
                              {label}
                            </div>
                          ),
                          SingleValue: CustomSingleValue,
                        }}
                      />
                    </div>
                    <div className="flex flex-row flex-nowrap justify-evenly mb-4">
                      <div className="">
                        <input
                          type="number"
                          name="fromCurrency"
                          placeholder="0.0"
                          onChange={handleBuyAmountChange}
                          value={
                            exchangeAmount.amount === 0
                              ? ""
                              : exchangeAmount.amount
                          }
                          required
                          className="max-h-[28px] max-w-[100px] rounded-[5px] text-right"
                        />
                        <label
                          htmlFor=""
                          className="text-[#FFFFFF] text-md font-mont font-[700] ml-2"
                        >
                          NGN
                        </label>
                      </div>
                      <span className="text-[#FFFFFF] text-md font-mont font-[700] md:ml-2 mr-4">
                        =
                      </span>
                      <div className="">
                        <input
                          type="number"
                          name="toCurrency"
                          placeholder="0.0"
                          readOnly={true}
                          value={
                            exchangeAmount.exchangeAmount === 0
                              ? ""
                              : exchangeAmount.exchangeAmount
                          }
                          className="max-h-[28px] max-w-[100px] rounded-[5px] text-right"
                          required
                        />
                        <label
                          htmlFor=""
                          className="text-[#FFFFFF] text-md font-mont font-[700] ml-2"
                        >
                          {exchangeAmount.toCurrency}
                        </label>
                      </div>
                    </div>

                    <div className="flex flex-col items-center md:flex-row flex-nowrap md:justify-evenly mb-4 text-md">
                      <div className="">
                        <span className="max-h-[28px] max-w-[100px] rounded-[5px] text-right text-white">
                          1
                        </span>
                        <label className="text-[#FFFFFF] text-md font-mont font-[700] ml-2">
                          NGN
                        </label>
                      </div>
                      <span className="text-[#FFFFFF] text-md font-mont font-[700] md:ml-2 mr-4">
                        =
                      </span>
                      <div className="">
                        <span className="max-h-[28px] max-w-[100px] rounded-[5px] text-right text-white">
                          {Number(
                            currency.find(
                              (d: any) => d.code === exchangeAmount.toCurrency
                            )?.buy
                          ) > 0
                            ? (
                                1 /
                                Number(
                                  currency.find(
                                    (d: any) =>
                                      d.code === exchangeAmount.toCurrency
                                  )?.buy
                                )
                              ).toFixed(6)
                            : 1}
                        </span>
                        <label className="text-[#FFFFFF] text-md font-mont font-[700] ml-2">
                          {exchangeAmount.toCurrency}
                        </label>
                      </div>
                    </div>
                    <Button
                      content="BUY CASH"
                      type="submit"
                      style={{ backgroundColor: "#C48827" }}
                    />
                  </form>
                </Tabs.Item>
                <Tabs.Item
                  title="SELL"
                  className="inline-block p-4 border-b-2 rounded-t-lg text-[#054B98] text-[24px]"
                >
                  {/* Sell Form */}
                  <form
                    className="flex flex-col gap-6 mx-4 md:mt-14 mt-8"
                    action=""
                    onSubmit={handleSubmitSellCurrency}
                  >
                    <div>
                      <p className="input-label text-white mb-5">
                        Enter your destination country / currency:
                      </p>
                      <Select
                        options={options}
                        styles={customStyles}
                        name="currency"
                        value={selectedOption}
                        onChange={handleSelectChange}
                        placeholder="Select Currency"
                        isSearchable={false}
                        components={{
                          Option: ({ innerProps, label, data }) => (
                            <div
                              {...innerProps}
                              className="flex flex-row px-auto mx-auto gap-8 text-[14px] mb-3 mt-2 cursor-pointer"
                            >
                              <img
                                src={data?.imgSrc}
                                alt={label}
                                className="h-5 w-6 my-auto ml-4"
                              />
                              {label}
                            </div>
                          ),
                          SingleValue: CustomSingleValue,
                        }}
                      />
                    </div>
                    <div className="flex flex-row flex-nowrap justify-evenly mb-4">
                      <div className="">
                        <input
                          type="number"
                          name="toCurrency"
                          placeholder="0.0"
                          onChange={handleSellAmountChange}
                          value={
                            exchangeAmount.amount === 0
                              ? ""
                              : exchangeAmount.amount
                          }
                          className="max-h-[28px] max-w-[100px] rounded-[5px] text-right"
                          required
                        />
                        <label
                          htmlFor=""
                          className="text-[#FFFFFF] text-md font-mont font-[700] ml-2"
                        >
                          {exchangeAmount.toCurrency}
                        </label>
                      </div>
                      <span className="text-[#FFFFFF] text-md font-mont font-[700] md:ml-2 mr-4">
                        =
                      </span>

                      <div className="">
                        <input
                          type="number"
                          name="fromCurrency"
                          placeholder="0.0"
                          readOnly={true}
                          value={
                            exchangeAmount.exchangeAmount === 0
                              ? ""
                              : exchangeAmount.exchangeAmount
                          }
                          required
                          className="max-h-[28px] max-w-[100px] rounded-[5px] text-right"
                        />
                        <label
                          htmlFor=""
                          className="text-[#FFFFFF] text-md font-mont font-[700] ml-2"
                        >
                          NGN
                        </label>
                      </div>
                    </div>

                    <div className="flex flex-col items-center md:flex-row flex-nowrap md:justify-evenly mb-4 text-md">
                      <div className="">
                        <span className="max-h-[28px] max-w-[100px] rounded-[5px] text-right text-white">
                          1
                        </span>
                        <label className="text-[#FFFFFF] text-md font-mont font-[700] ml-2">
                          {exchangeAmount.toCurrency}
                        </label>
                      </div>
                      <span className="text-[#FFFFFF] text-md font-mont font-[700] md:ml-2 mr-4">
                        =
                      </span>

                      <div className="">
                        <span className="max-h-[28px] max-w-[100px] rounded-[5px] text-right text-white">
                          {Number(
                            currency.find(
                              (d: any) => d.code === exchangeAmount.toCurrency
                            )?.buy
                          ) > 0
                            ? Number(
                                currency.find(
                                  (d: any) =>
                                    d.code === exchangeAmount.toCurrency
                                )?.sell
                              ) * 1
                            : 1}
                        </span>
                        <label className="text-[#FFFFFF] text-md font-mont font-[700] ml-2">
                          NGN
                        </label>
                      </div>
                    </div>
                    <Button
                      content="SELL CASH"
                      type="submit"
                      style={{ backgroundColor: "#C48827" }}
                    />
                  </form>
                </Tabs.Item>
              </Tabs>
            </div>
          </div>
          {/* surmary */}
          <div className="parent-container flex items-center justify-center rounded bg-white h-full dark:bg-gray-800 xl:col-start-1 xl:col-span-2 xl:row-span-7 lg:col-start-1 lg:col-span-2 lg:row-span-7 md:col-start-1 md:col-span-6 md:row-span-2 row-span-2 col-span-3 col-start-1">
            <div className="w-full h-full ">
              <ResponsiveTable data={currency} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Overview);
