import React, { useState } from "react";
// eslint-disable-next-line
import { Link, useNavigate } from "react-router-dom";
import BaseDirectories from "../../base directories/BaseDirectories";
import Button from "../../components/ui/Button";

const ForgotPassword = () => {
  //   const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({
      email: "",
    });
  //   const navigate = useNavigate();

    const handleInputChange = (e: React.FormEvent) => {
      e.preventDefault();

      const element = e.target as HTMLInputElement;
      setUserDetails((prevDetails: any) => {
        return {
          ...prevDetails,
          [element.name]:
            element.name === "email"
              ? element.value.trim().toLowerCase()
              : element.value.trim(),
        };
      });
    };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 h-dvh pt-auto">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <Link to="/" className="flex items-center mb-4">
          <img
            src={`${BaseDirectories.LOGOS_DIR}/aeroconvert-logo.svg`}
            alt="Aeroconvert Logo"
          />
        </Link>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Forgot your password?
            </h1>
            <p className="font-light text-gray-500 dark:text-gray-400">
              Don't fret! Just type in your email and we will send you a likn to
              reset your password!
            </p>
            <form className="space-y-4 md:space-y-6" action="#">
              <div>
                <label htmlFor="email" className="input-label">
                  Email <span className="text-[#FF4B00]">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="input-control"
                  value={userDetails.email}
                  onChange={handleInputChange}
                  placeholder="name@domain.com"
                  required
                />
              </div>
              <div className="flex items-center justify-between"></div>

              <Button content="RESET PASSWORD" type="submit" />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
