// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-image,
.overlay-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
  padding: 20px 5px;
}

.background-image {
  left: 20px;
  top: 40px;
  /* max-width: 537px;
  max-height: 431px; */
  z-index: 1;
  position: absolute;
}

.overlay-image {
  /* max-width: 507px;
  max-height: 421px; */
  z-index: 2;
  position: relative;
}

@media only screen and (max-width: 694px) {
  .image-container {
    padding: 30px;
  }
  .background-image {
    width: 85%;
    height: 87%;
    left: 86px;
    top: 80px;
  }
  .overlay-image {
    width: 96%;
    height: 100%;
  }
}

/* @media only screen and (max-width: 767px) {
    .image-container {
        padding: 30px;
    }
    .background-image {
        width: 90%;
        height: 93%;
        left: 80px;
        top: 80px;
      }
} */
`, "",{"version":3,"sources":["webpack://./src/styles/landing_page.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,iBAAiB,EAAE,0CAA0C;EAC7D,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,SAAS;EACT;sBACoB;EACpB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE;sBACoB;EACpB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,UAAU;IACV,WAAW;IACX,UAAU;IACV,SAAS;EACX;EACA;IACE,UAAU;IACV,YAAY;EACd;AACF;;AAEA;;;;;;;;;;GAUG","sourcesContent":[".image-container {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.background-image,\n.overlay-image {\n  width: 100%;\n  height: 100%;\n  object-fit: cover; /* Ensure the image covers the container */\n  padding: 20px 5px;\n}\n\n.background-image {\n  left: 20px;\n  top: 40px;\n  /* max-width: 537px;\n  max-height: 431px; */\n  z-index: 1;\n  position: absolute;\n}\n\n.overlay-image {\n  /* max-width: 507px;\n  max-height: 421px; */\n  z-index: 2;\n  position: relative;\n}\n\n@media only screen and (max-width: 694px) {\n  .image-container {\n    padding: 30px;\n  }\n  .background-image {\n    width: 85%;\n    height: 87%;\n    left: 86px;\n    top: 80px;\n  }\n  .overlay-image {\n    width: 96%;\n    height: 100%;\n  }\n}\n\n/* @media only screen and (max-width: 767px) {\n    .image-container {\n        padding: 30px;\n    }\n    .background-image {\n        width: 90%;\n        height: 93%;\n        left: 80px;\n        top: 80px;\n      }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
