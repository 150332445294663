import React from "react";
import MainRoutes from "./routes/MainRoutes";
import ToastNotification from "./components/ToastNotification";

function App() {
  return (
    <>
      <ToastNotification />
      <MainRoutes />
    </>
  );
}

export default App;
