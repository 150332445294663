/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import BaseDirectories from "../../base directories/BaseDirectories";
import "../../styles/landing_page.css";

const OurService = () => {
  return (
    <div className="pr-4">
      {/* Payment Facilitation */}
      <section className="bg-white dark:bg-gray-900">
        <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <div className="mt-4 md:mt-0">
            <h2 className="mb-4 md:text-5xl text-3xl font-[700] text-[#054B98]">
              Payment Facilitation
            </h2>
            <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
              We specialize in facilitating secure and efficient payment
              transactions for the acquisition, leasing, and financing of
              jetliners as well as payment for chartered flights
            </p>
          </div>
          <img
            className="w-full dark:hidden"
            src={`${BaseDirectories.IMAGES_DIR}/payment_facility.png`}
            alt="Payment Facilitation"
          />
        </div>
      </section>
      {/* Loans */}
      <section className="bg-white dark:bg-gray-900">
        <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col">
          <div className="w-full order-last md:order-first">
            <img
              className="dark:hidden"
              src={`${BaseDirectories.IMAGES_DIR}/loan.png`}
              alt="loans"
            />
          </div>

          <div className="mt-4 md:mt-0 order-first md:order-last">
            <h2 className="mb-4 md:text-5xl text-3xl font-[700] text-[#054B98]">
              Loans
            </h2>
            <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
              Do you have liquidity issues in making a chartered flight payment?
              We offer short term loans to enable you make your trips and you
              can conveniently pay us later.
            </p>
          </div>
        </div>
      </section>
      {/* Customized Solutions */}
      <section className="bg-white dark:bg-gray-900">
        <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <div className="mt-4 md:mt-0">
            <h2 className="mb-4 md:text-5xl text-3xl font-[700] text-[#054B98]">
              Customized Solutions
            </h2>
            <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
              Recognizing the diverse needs of our clients, we offer bespoke
              payment solutions tailored to individual transactions and can
              cater to your every need!
            </p>
          </div>
          <img
            className="w-full dark:hidden"
            src={`${BaseDirectories.IMAGES_DIR}/custom_solution.png`}
            alt="Payment Facilitation"
          />
        </div>
      </section>
      {/* Consultative Approach */}
      <section className="bg-white dark:bg-gray-900">
        <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col">
          <div className="w-full order-last md:order-first">

            <img
              className="dark:hidden"
              src={`${BaseDirectories.IMAGES_DIR}/consult.png`}
              alt="consult"
            />
          </div>

          <div className="mt-4 md:mt-0 order-first md:order-last">
            <h2 className="mb-4 md:text-5xl text-3xl font-[700] text-[#054B98]">
              Consultative Approach
            </h2>
            <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
              Rest while we handle your transactions safely. Our team of experts
              provides consultative support and guidance to our clients.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurService;
