import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../widgets/sidebar/Sidebar";
import Header from "../../widgets/header/Header";
import DashboardRoute from "../../routes/DashboardRoute";
import { observer } from "mobx-react-lite";

const Dashboard = () => {
  return (
    <>
      <Sidebar />
      <Header />
      <div className="right-content-container">
        <DashboardRoute />
      </div>
    </>
  );
};

export default observer(Dashboard);
