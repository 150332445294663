import React from "react";
const Button = ({style, type, content, onClick}: any) => {

    return (
        <button
        type={type}
        className="w-full h-[48px] text-white bg-[#054B98] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-[5px] md:text-lg text-sm font-[700] px-5 py-2.5 text-center"
        style={style}
        onClick={onClick}
      >
        {content}
      </button>
    )
}

export default Button;