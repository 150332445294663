import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { StoreContext } from "../../mobx stores/RootStore";
import { Button } from "flowbite-react";
import { observer } from "mobx-react-lite";
import Spinner from "../Spinner";

const UpdatePin = () => {
  const { profileStore } = useContext(StoreContext);
  const { profile, isPinExist } = profileStore;
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [pinToggle, setPinToggle] = useState(false);
  const [pin, setPin] = useState({
    newPin: "string",
    confirmPin: "string",
  });
  const [oldPin, setOldPin] = useState("");
  const [changeNewPin, setChangeNewPin] = useState("");
  const [confirmChangeNewPin, setConfirmChangeNewPin] = useState("");
  const [updatePin, setUpdatePin] = useState({
    oldPin: "string",
    newPin: "string",
    confirmPin: "string",
  });

  const { submitting, message } = profileStore;

  useEffect(() => {
    setPin({
      newPin,
      confirmPin,
    });
  }, [newPin, confirmPin]);

  useEffect(() => {
    setUpdatePin({
      oldPin,
      newPin: changeNewPin,
      confirmPin: confirmChangeNewPin,
    });
  }, [oldPin, changeNewPin, confirmChangeNewPin]);

  const handleChangePin = () => {
    if (updatePin.oldPin === "") {
      toast.error("Invalid old pin");
      return;
    }
    if (
      updatePin.newPin !== updatePin.confirmPin ||
      updatePin.newPin === "" ||
      updatePin.confirmPin === ""
    ) {
      toast.error("Pin do not match");
      return;
    }
    profileStore.updatePin(updatePin);
  };

  const handleResetPin = () => {
    profileStore.resetPin();
  };
  const handlePinToggle = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    setPinToggle(element.checked);
  };

  const validateNumber = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  useEffect(() => {
    if (
      message.type === "success" &&
      message.msg === "Pin Update is successfully!"
    ) {
      setOldPin("");
      setChangeNewPin("");
      setConfirmChangeNewPin("");
    } else if (
      message.type === "success" &&
      message.msg === "Pin reset successfully!"
    ) {
      setOldPin("");
      setChangeNewPin("");
      setConfirmChangeNewPin("");
    }
  }, [message.type, message.msg]);

  return (
    <section className="bg-gray-50">
    <div className="flex flex-col items-center justify-center lg:py-0 ">
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md md:p-10 p-6 space-y-4 md:space-y-6 sm:p-8">
        {submitting && <Spinner />}
        <div className="col-md-12 input-box mb-3">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white mb-4">
            Change Pin
          </h1>
          <div>
            <label className="mb-2">
              Enter Old Pin
              <span style={{ color: "red" }}> *</span>
            </label>
            <OtpInput
              value={oldPin}
              onChange={setOldPin}
              numInputs={4}
              inputStyle="pin-style"
              containerStyle="pin-container-style"
              inputType={pinToggle ? "tel" : "password"}
              shouldAutoFocus={true}
              renderInput={(props) => (
                <input onKeyPress={validateNumber} {...props} />
              )}
            />
          </div>
        </div>
        <div className="col-md-12 input-box mb-3">
          <div>
            <label className="mb-2">
              Enter New Pin
              <span style={{ color: "red" }}> *</span>
            </label>
            <OtpInput
              value={changeNewPin}
              onChange={setChangeNewPin}
              numInputs={4}
              inputStyle="pin-style"
              containerStyle="pin-container-style"
              inputType={pinToggle ? "tel" : "password"}
              shouldAutoFocus={true}
              renderInput={(props) => (
                <input onKeyPress={validateNumber} {...props} />
              )}
            />
          </div>
        </div>
        <div className="col-md-12 input-box">
          <div>
            <label className="mb-2">
              Confirm New Pin
              <span style={{ color: "red" }}> *</span>
            </label>
            <OtpInput
              value={confirmChangeNewPin}
              onChange={setConfirmChangeNewPin}
              numInputs={4}
              inputStyle="pin-style"
              containerStyle="pin-container-style"
              inputType={pinToggle ? "tel" : "password"}
              shouldAutoFocus={false}
              renderInput={(props) => (
                <input onKeyPress={validateNumber} {...props} />
              )}
            />
          </div>
        </div>
        <div className="flex gap-2 justify-center items-center mb-3">
          <input
            type="checkbox"
            onChange={handlePinToggle}
            className="btn-switch"
            name="showPin"
            id="show-pin"
          />
          <label htmlFor="show-pin">Show pin</label>
        </div>
        <div className="flex flex-row justify-center text-center mt-2">
          <div className="profile-btn m-2">
            <Button
              type="button"
              disabled={submitting}
              onClick={() => {
                handleChangePin();
              }}
            >
              {submitting ? "Changing..." : "Change Pin"}
            </Button>
          </div>

          <div className="profile-btn m-2">
            <Button
              type="button"
              color="failure"
              disabled={submitting}
              onClick={handleResetPin}
            >
              {submitting ? "Resetting..." : "Reset Pin"}
            </Button>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
};

export default observer(UpdatePin);
