import { initFlowbite } from "flowbite";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import BaseDirectories from "../../base directories/BaseDirectories";

const FaqSection = ({ ref }: any) => {
  useEffect(() => {
    initFlowbite();
  }, []);

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.substring(1);
    if (hash === "faq-section") {
      window.scrollTo(0, 900);
    }
  }, [location]);

  return (
    <div className="px-auto">
      {/*Contact Us*/}
      <section className="bg-white" >
        <div className="py-[40px] px-[20px] mx-4 md:mx-auto max-w-screen-xl md:py-[80px] md:px-[38px] bg-[#054B98] rounded-[20px] mb-16">
          <h2 className=" text-center mb-8 text-4xl tracking-tight font-extrabold text-white">
            Frequently asked questions
          </h2>

          <div
            id="accordion-arrow-icon"
            data-accordion="open"
            className="md:p-5 p-1"
          >
            <div className="mb-4">
              <h2 id="accordion-1 bg-white ">
                <button
                  type="button"
                  className="flex bg-white rounded-tr-[10px] rounded-tl-[10px] items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 gap-3"
                  data-accordion-target="#accordion-1"
                  aria-expanded="false"
                  aria-controls="accordion-arrow-icon-body-3"
                >
                  <span className="text-[#054B99]">
                    What services does Aeroconvert provide in the aviation
                    industry?
                  </span>
                  <svg
                    data-accordion-icon
                    className="w-[30px] h-[30px] rotate-180 shrink-0 bg-[#054B98] p-2 rounded-[50px] text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-1"
                className="hidden bg-white mb-4 rounded-br-[10px] rounded-bl-[10px] pt-6"
                aria-labelledby="accordion-arrow-icon-heading-3"
              >
                <div className="p-5 ">
                  <p className="mb-2 text-gray-600">
                    Absolutely! Recognizing the diverse needs of our clients,
                    Aeroconvert provides bespoke payment solutions tailored to
                    individual transactions. Our goal is to cater to your unique
                    requirements and ensure a seamless payment experience.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <h2 id="accordion-2 bg-white ">
                <button
                  type="button"
                  className="flex bg-white rounded-tr-[10px] rounded-tl-[10px] items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 gap-3"
                  data-accordion-target="#accordion-2"
                  aria-expanded="false"
                  aria-controls="accordion-arrow-icon-body-3"
                >
                  <span className="text-[#054B99]">
                    How does Aeroconvert handle payment transactions securely?
                  </span>
                  <svg
                    data-accordion-icon
                    className="w-[30px] h-[30px] rotate-180 shrink-0 bg-[#054B98] p-2 rounded-[50px] text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-2"
                className="hidden bg-white mb-4 rounded-br-[10px] rounded-bl-[10px] pt-6"
                aria-labelledby="accordion-arrow-icon-heading-3"
              >
                <div className="p-5 ">
                  <p className="mb-2 text-gray-600">
                    Absolutely! Recognizing the diverse needs of our clients,
                    Aeroconvert provides bespoke payment solutions tailored to
                    individual transactions. Our goal is to cater to your unique
                    requirements and ensure a seamless payment experience.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <h2 id="accordion-3 bg-white ">
                <button
                  type="button"
                  className="flex bg-white rounded-tr-[10px] rounded-tl-[10px] items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 gap-3"
                  data-accordion-target="#accordion-3"
                  aria-expanded="false"
                  aria-controls="accordion-arrow-icon-body-3"
                >
                  <span className="text-[#054B99]">
                    Can Aeroconvert assist with liquidity issues for chartered
                    flight payments?
                  </span>
                  <svg
                    data-accordion-icon
                    className="w-[30px] h-[30px] rotate-180 shrink-0 bg-[#054B98] p-2 rounded-[50px] text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-3"
                className="hidden bg-white mb-4 rounded-br-[10px] rounded-bl-[10px] pt-6"
                aria-labelledby="accordion-arrow-icon-heading-3"
              >
                <div className="p-5 ">
                  <p className="mb-2 text-gray-600">
                    Absolutely! Recognizing the diverse needs of our clients,
                    Aeroconvert provides bespoke payment solutions tailored to
                    individual transactions. Our goal is to cater to your unique
                    requirements and ensure a seamless payment experience.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <h2 id="accordion-4 bg-white ">
                <button
                  type="button"
                  className="flex bg-white rounded-tr-[10px] rounded-tl-[10px] items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 gap-3"
                  data-accordion-target="#accordion-4"
                  aria-expanded="false"
                  aria-controls="accordion-arrow-icon-body-3"
                >
                  <span className="text-[#054B99]">
                    Are Aeroconvert's payment solutions customizable?
                  </span>
                  <svg
                    data-accordion-icon
                    className="w-[30px] h-[30px] rotate-180 shrink-0 bg-[#054B98] p-2 rounded-[50px] text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-4"
                className="hidden bg-white mb-4 rounded-br-[10px] rounded-bl-[10px] pt-6"
                aria-labelledby="accordion-arrow-icon-heading-3"
              >
                <div className="p-5 ">
                  <p className="mb-2 text-gray-600">
                    Absolutely! Recognizing the diverse needs of our clients,
                    Aeroconvert provides bespoke payment solutions tailored to
                    individual transactions. Our goal is to cater to your unique
                    requirements and ensure a seamless payment experience.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <h2 id="accordion-5 bg-white ">
                <button
                  type="button"
                  className="flex bg-white rounded-tr-[10px] rounded-tl-[10px] items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 gap-3"
                  data-accordion-target="#accordion-5"
                  aria-expanded="false"
                  aria-controls="accordion-arrow-icon-body-3"
                >
                  <span className="text-[#054B99]">
                    How does Aeroconvert support clients throughout
                    transactions?
                  </span>
                  <svg
                    data-accordion-icon
                    className="w-[30px] h-[30px] rotate-180 shrink-0 bg-[#054B98] p-2 rounded-[50px] text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-5"
                className="hidden bg-white mb-4 rounded-br-[10px] rounded-bl-[10px] pt-6"
                aria-labelledby="accordion-arrow-icon-heading-3"
              >
                <div className="p-5 ">
                  <p className="mb-2 text-gray-600">
                    Absolutely! Recognizing the diverse needs of our clients,
                    Aeroconvert provides bespoke payment solutions tailored to
                    individual transactions. Our goal is to cater to your unique
                    requirements and ensure a seamless payment experience.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <h2 id="accordion-6 bg-white ">
                <button
                  type="button"
                  className="flex bg-white rounded-tr-[10px] rounded-tl-[10px] items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 gap-3"
                  data-accordion-target="#accordion-6"
                  aria-expanded="false"
                  aria-controls="accordion-arrow-icon-body-3"
                >
                  <span className="text-[#054B99]">
                    How can I get in touch with Aeroconvert for further
                    assistance?
                  </span>
                  <svg
                    data-accordion-icon
                    className="w-[30px] h-[30px] rotate-180 shrink-0 bg-[#054B98] p-2 rounded-[50px] text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-6"
                className="hidden bg-white mb-4 rounded-br-[10px] rounded-bl-[10px] pt-6"
                aria-labelledby="accordion-arrow-icon-heading-3"
              >
                <div className="p-5 ">
                  <p className="mb-2 text-gray-600">
                    Absolutely! Recognizing the diverse needs of our clients,
                    Aeroconvert provides bespoke payment solutions tailored to
                    individual transactions. Our goal is to cater to your unique
                    requirements and ensure a seamless payment experience.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <h2 id="accordion-7 bg-white ">
                <button
                  type="button"
                  className="flex bg-white rounded-tr-[10px] rounded-tl-[10px] items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 gap-3"
                  data-accordion-target="#accordion-7"
                  aria-expanded="false"
                  aria-controls="accordion-arrow-icon-body-3"
                >
                  <span className="text-[#054B99]">
                    How can I get in touch with Aeroconvert for further
                    assistance?
                  </span>
                  <svg
                    data-accordion-icon
                    className="w-[30px] h-[30px] rotate-180 shrink-0 bg-[#054B98] p-2 rounded-[50px] text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-7"
                className="hidden bg-white mb-4 rounded-br-[10px] rounded-bl-[10px] pt-6"
                aria-labelledby="accordion-arrow-icon-heading-3"
              >
                <div className="p-5 ">
                  <p className="mb-2 text-gray-600">
                    Absolutely! Recognizing the diverse needs of our clients,
                    Aeroconvert provides bespoke payment solutions tailored to
                    individual transactions. Our goal is to cater to your unique
                    requirements and ensure a seamless payment experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="py-0 px-4 mx-auto max-w-screen-xl text-center lg:py-4 lg:px-6">
          <div className="mx-auto max-w-screen-sm">
            <h2 className="mb-4 text-4xl tracking-tight font-semibold text-gray-900 dark:text-white">
              Still have questions? get in touch with us
            </h2>
            <p className="mb-8 font-light text-gray-500 lg:mb-5 sm:text-md dark:text-gray-400">
              Your satisfaction is our top priority! our support service is
              available 24/7 to assist you with any questions you have about
              Aeroconvert and our services; loans, customized solutions, payment
              facilitation etc,
            </p>
            <p className="mb-8 font-semibold text-gray-500 lg:mb-16 sm:text-md dark:text-gray-400">
              You can get in touch with us any way you prefer:
            </p>
          </div>

          <div className="grid grid-cols-1 gap-0 sm:gap-0 sm:grid-cols-1 mb-8">
            <div className="flex md:justify-center md:items-center justify-center">
              <Link
                to=""
                className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/In.svg`}
                  className="h-[60px] w-[60px]"
                  alt="Aeroconvert Logo"
                />
              </Link>
              <Link
                to=""
                className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/Insta.svg`}
                  alt="Aeroconvert Logo"
                  className="h-[60px] w-[60px]"
                />
              </Link>
              <Link
                to=""
                className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/Twit.svg`}
                  alt="Aeroconvert Logo"
                  className="h-[60px] w-[60px]"
                />
              </Link>
              <Link
                to=""
                className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/Fbook.svg`}
                  alt="Aeroconvert Logo"
                  className="h-[60px] w-[60px]"
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-0 sm:gap-0 sm:grid-cols-1 mb-8">
          <div className="flex md:justify-center md:items-center justify-center">
            <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
            <div className="flex flex-col p-6 mx-auto max-w-[350px] max-h-[184px] text-center justify-center item-center text-gray-900 bg-white rounded-[20px] border border-gray-100 shadow">
              <img
                  src={`${BaseDirectories.ICONS_DIR}/mail.png`}
                  className="h-[30px] w-[30px] place-self-center mb-2"
                  alt="Aeroconvert Logo"
                />
                <h3 className="mb-4 text-lg text-[#6E7191] font-semibold uppercase">Send us a mail</h3>
                <div className="flex justify-center items-baseline my-3">
                  <span className="text-[#054B99] text-sm">
                  info@aeroconvert.com
                  </span>
                </div>
              </div>

              <div className="flex flex-col p-6 mx-auto max-w-[350px] max-h-[184px] text-center justify-center item-center text-gray-900 bg-white rounded-[20px] border border-gray-100 shadow">
              <img
                  src={`${BaseDirectories.ICONS_DIR}/phone.png`}
                  className="h-[30px] w-[30px] place-self-center mb-2"
                  alt="Aeroconvert Logo"
                />
                <h3 className="mb-4 text-lg text-[#6E7191] font-semibold uppercase">give us a Call us</h3>
                <div className="flex justify-center items-baseline my-3">
                  <span className="text-[#054B99] text-sm">
                  +234 817 079 6881
                  </span>
                </div>
              </div>

              <div className="flex flex-col p-6 mx-auto max-w-[350px] max-h-[184px] text-center justify-center item-center text-gray-900 bg-white rounded-[20px] border border-gray-100 shadow">
              <img
                  src={`${BaseDirectories.ICONS_DIR}/whatsapp.png`}
                  className="h-[30px] w-[30px] place-self-center mb-2"
                  alt="Aeroconvert Logo"
                />
                <h3 className="mb-4 text-lg text-[#6E7191] font-semibold uppercase">chat with us</h3>
                <div className="flex justify-center items-baseline my-3">
                  <span className="text-[#054B99] text-sm">
                  Chat with us on Whatsapp
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FaqSection;
