import React, { FormEvent, useContext, useEffect, useState } from "react";
// eslint-disable-next-line
import { Link, useNavigate } from "react-router-dom";
import BaseDirectories from "../../base directories/BaseDirectories";
import Button from "../../components/ui/Button";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import Icon from "@mdi/react";
import { StoreContext } from "../../mobx stores/RootStore";
import Spinner from "../../components/Spinner";
import { observer } from "mobx-react-lite";

const ChangePassword = () => {
  //   const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordToggleValues, setPasswordToggleValues] = useState({
    showPassword: "",
  });
  //Profile store context
  const { authStore } = useContext(StoreContext);
  const { submitting, message } = authStore;
const navigate = useNavigate();
  const handlePasswordChange = (e: FormEvent) => {
    e.preventDefault();

    authStore.changePassword(currentPassword, newPassword, confirmPassword);
  };

  useEffect(() => {
    if (message.type === "success" && message.msg === "Password updated successfully.") {
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      // navigate('/dashboard/settings');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.msg, message.type]);

  const handleClickShowPassword = (fieldName: string) => {
    setPasswordToggleValues({
      ...passwordToggleValues,
      showPassword:
        fieldName === passwordToggleValues.showPassword ? "" : fieldName,
    });
  };

  return (
    <section className="bg-gray-50 h-[calc(100vh_-_240px)] md:w-[calc(100vw_-_480px)]">
      {submitting && <Spinner />}
      <div className="flex flex-col items-center justify-center lg:py-0">
        <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Change Password
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handlePasswordChange}>
              <div>
                <label htmlFor="password" className="input-label">
                  Current Password <span className="text-[#FF4B00]">*</span>
                </label>
                <div className="relative">
                  <input
                    type={
                      passwordToggleValues.showPassword === "currentPassword"
                        ? "text"
                        : "password"
                    }
                    className="input-password"
                    name="currentPassword"
                    id="currentPassword"
                    onChange={(e: any) => setCurrentPassword(e.target.value)}
                    value={currentPassword}
                    placeholder="Old password"
                  />
                  <div
                    className="absolute inset-y-0 end-3 flex items-center ps-3.5 cursor-pointer"
                    onClick={() => handleClickShowPassword("currentPassword")}
                  >
                    <Icon
                      path={
                        passwordToggleValues.showPassword === "currentPassword"
                          ? mdiEyeOff
                          : mdiEye
                      }
                      vertical
                      size={1}
                    />
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="newPassword" className="input-label">
                  New password <span className="text-[#FF4B00]">*</span>
                </label>
                <div className="relative">
                  <input
                    className="input-password"
                    value={newPassword}
                    type={
                      passwordToggleValues.showPassword === "newPassword"
                        ? "text"
                        : "password"
                    }
                    id="newPassword"
                    placeholder="New password"
                    onChange={(e: any) => setNewPassword(e.target.value)}
                  />
                  <div
                    className="absolute inset-y-0 end-3 flex items-center ps-3.5 cursor-pointer"
                    onClick={() => handleClickShowPassword("newPassword")}
                  >
                    <Icon
                      path={
                        passwordToggleValues.showPassword === "newPassword"
                          ? mdiEyeOff
                          : mdiEye
                      }
                      vertical
                      size={1}
                    />
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="confirmPassword" className="input-label">
                  Confirm password <span className="text-[#FF4B00]">*</span>
                </label>
                <div className="relative">
                  <input
                    className="input-password"
                    name="confirmPassword"
                    value={confirmPassword}
                    type={
                      passwordToggleValues.showPassword === "confirmPassword"
                        ? "text"
                        : "password"
                    }
                    id="confirmPassword"
                    placeholder="Confirm password"
                    onChange={(e: any) => setConfirmPassword(e.target.value)}
                  />
                  <div
                    className="absolute inset-y-0 end-3 flex items-center ps-3.5 cursor-pointer"
                    onClick={() => handleClickShowPassword("confirmPassword")}
                  >
                    <Icon
                      path={
                        passwordToggleValues.showPassword === "confirmPassword"
                          ? mdiEyeOff
                          : mdiEye
                      }
                      vertical
                      size={1}
                    />
                  </div>
                </div>
              </div>

              <Button
                content="SAVE CHANGES"
                type="submit"
                className="text-md"
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default observer(ChangePassword);
