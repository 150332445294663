/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";

const PayVendorModal = () => {
  const [showSummary, setShowSummary] = useState(false);
  const [formData, setFormData] = useState({
    vendorName: "",
    purposeOfPayment: "",
    accountNumber: "",
    address: "",
    state: "",
    zipCode: "",
    country: "",
    swiftCode: "",
    amount: "",
    description: "",
  });

  useEffect(() => {
    const hasData = Object.values(formData).some((value) => value !== "");
    setShowSummary(hasData);
  }, [formData]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getCurrentDate = () => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const currentDate = new Date()
      .toLocaleDateString("en-GB", options)
      .replace(/\//g, ".");
    return currentDate;
  };

  const getCurrentTime = () => {
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const currentTime = new Date().toLocaleTimeString("en-US", options);
    return currentTime;
  };
  return (
    <div className="flex flex-col xl:flex-row justify-between items-center md:gap-[78px] gap-4 w-full">
      <div className="bg-[#FFF] md:p-10 p-4 flex flex-col w-auto min-w-[300px] md:min-w-[350px] max-w-[200px] xl:ml-[20px] ml-0 min-h-[400px]">
        <div className="border-[#AFB6E5] border-b-2 flex justify-between items-center p-2 w-full">
          <h1 className="font-mont text-[#054B98] font-bold text-lg">
            Vendor details
          </h1>
        </div>
        <form className="w-full" onChange={handleInputChange}>
          <div>
            <label className="text-[#000] font-mont text-sm font-medium">
              Name of vendor
            </label>
            <input
              type="text"
              className="input-control"
              name="vendorName"
              value={formData.vendorName}
            />
          </div>
          <div>
            <label className="text-[#000] font-mont text-sm font-medium">
              Purpose of Payment
            </label>
            <input
              type="text"
              className="input-control"
              name="purposeOfPayment"
              value={formData.purposeOfPayment}
            />
          </div>
          <div>
            <label className="text-[#000] font-mont text-sm font-medium">
              Account number
            </label>
            <input
              type="text"
              className="input-control"
              name="accountNumber"
              value={formData.accountNumber}
            />
          </div>
          <div>
            <label className="text-[#000] font-mont text-sm font-medium">
              Address
            </label>
            <input
              type="text"
              className="input-control"
              name="address"
              value={formData.address}
            />
          </div>
          <div className="flex flex-row gap-2 ">
            <div>
              <label className="text-[#000] font-mont text-sm font-medium">
                State
              </label>
              <input
                type="text"
                className="input-control"
                name="state"
                value={formData.state}
              />
            </div>
            <div>
              <label className="text-[#000] font-mont text-sm font-medium">
                Zip/Postal code
              </label>
              <input
                type="text"
                className="input-control"
                name="zipCode"
                value={formData.zipCode}
              />
            </div>
          </div>
          <div>
            <label className="text-[#000] font-mont text-sm font-medium">
              Country
            </label>
            <input
              type="text"
              className="input-control"
              name="country"
              value={formData.country}
            />
          </div>
          <div>
            <label className="text-[#000] font-mont text-sm font-medium">
              Swift Code
            </label>
            <input
              type="text"
              className="input-control"
              name="swiftCode"
              value={formData.swiftCode}
            />
          </div>
          <div>
            <label className="text-[#000] font-mont text-sm font-medium">
              Amount
            </label>
            <input
              type="text"
              className="input-control"
              name="amount"
              value={formData.amount}
            />
          </div>
          <div>
            <label className="text-[#000] font-mont text-sm font-medium">
              Description
            </label>
            <input
              type="text"
              className="input-control"
              name="description"
              value={formData.description}
            />
          </div>
        </form>
      </div>

      <div className="bg-[#FFF] space-y-5 md:p-10 p-4 flex flex-col w-auto min-w-[300px] md:min-w-[350px] max-w-[200px] min-h-[400px]">
        <h2 className="text-[#054B98] font-bold text-lg mb-2 border-b-2 border-[#AFB6E5]">
          Summary for Payment
        </h2>
        <div className="flex flex-row justify-between ">
          <div className="text-[#D9D9D9] flex flex-col gap-2 font-mont font-bold text-sm">
            <p>Name of Supplier</p>
            <p>Account Number</p>
            <p>Amount</p>
            <p>Address</p>
            <p>Country</p>
            <p>Zip/Postal Code</p>
            <p>State</p>
            <p>Swift Code</p>
            <p>Description</p>
            <p>Date</p>
            <p>Time</p>
          </div>
          <div className="text-[#000] flex flex-col gap-2 font-mont font-bold text-sm text-end">
            <p>{formData.vendorName}</p>
            <p>{formData.purposeOfPayment}</p>
            <p>{formData.accountNumber}</p>
            <p>{formData.address}</p>
            <p>{formData.country}</p>
            <p>{formData.zipCode}</p>
            <p>{formData.state}</p>
            <p>{formData.swiftCode}</p>
            <p>{formData.amount}</p>
            <p>{formData.description}</p>
            <p>{getCurrentDate()}</p>
            <p>{getCurrentTime()}</p>
          </div>
        </div>
        <div className="flex flex-row  justify-center mt-4">
          <button className="bg-[#054B98] rounded-[25px] w-[200px] h-[30px] text-[#FFF] mont-font text-sm font-bold">
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default PayVendorModal;
