import React, { useContext, useState } from "react";
// eslint-disable-next-line
import { Link, useNavigate, useLocation } from "react-router-dom";
import BaseDirectories from "../../base directories/BaseDirectories";
import Button from "../../components/ui/Button";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import Icon from "@mdi/react";
import { StoreContext } from "../../mobx stores/RootStore";
import { toast } from "react-toastify";
import { LogIn } from "../../utils/AuthFirestore";
import { auth } from "../../firebase/firebase";
import Spinner from "../../components/Spinner";
import { observer } from "mobx-react-lite";

const Login = () => {
  const { authStore } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const location: any = useLocation();

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setUserDetails((prevDetails: any) => {
      return {
        ...prevDetails,
        [element.name]:
          element.name === "email"
            ? element.value.trim().toLowerCase()
            : element.value.trim(),
      };
    });
  };

  const SignIn = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await LogIn(userDetails);

      if (res.error === false) {
        window.sessionStorage.setItem('authUser', JSON.stringify(res.data?.user));
        // setLoading(false);
        setTimeout(() => setLoading(true), 800);
        toast.success("Login Successful!");
        setTimeout(() => {
          if (!auth.currentUser?.emailVerified) {
            toast.dismiss();
            navigate("/dashboard/overview");
            // navigate("/email_verification");
          } else {
            if (location?.state?.from?.pathname?.length > 0) {
              toast.dismiss();
              navigate(location?.state?.from?.pathname, { state: null });
            } else {
              toast.dismiss();
              // get profile details

              //get wallet details
              navigate("/dashboard/overview");
            }
          }
          setLoading(false);
        }, 3000);
      } else {
        setLoading(false);

        if (res.data.message === "Firebase: Error (auth/user-not-found).") {
          toast.error("User not found", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            pauseOnFocusLoss: false,
          });
          return;
        } else if (
          res.data.message === "Firebase: Error (auth/invalid-credential)."
        ) {
          toast.error("Login credentials are invalid.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            pauseOnFocusLoss: false,
          });
          return;
        } else if (
          res.data.message === "Firebase: Error (auth/wrong-password)."
        ) {
          toast.error("Invalid password", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            pauseOnFocusLoss: false,
          });
          return;
        } else if (
          res.data.message === "Firebase: Error (auth/invalid-email)."
        ) {
          toast.error("Invalid email", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            pauseOnFocusLoss: false,
          });
          return;
        } else if (
          res.data.message === "Firebase: Error (auth/network-request-failed)."
        ) {
          toast.error(
            "Network Request Failed. Please check your network connection",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              pauseOnFocusLoss: false,
            }
          );
          return;
        } else if (
          res.data.message ===
          "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."
        ) {
          toast.error(
            "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              pauseOnFocusLoss: false,
            }
          );
          return;
        } else {
          toast.error(
            "We are unable to process your form submission at this time. Please try again later!",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              pauseOnFocusLoss: false,
            }
          );
          return;
        }
      }
    } catch (err) {}
    // }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 h-dvh pt-auto">
      {loading && <Spinner />}
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <Link to="/" className="flex items-center mb-4">
          <img
            src={`${BaseDirectories.LOGOS_DIR}/aeroconvert-logo.svg`}
            alt="Aeroconvert Logo"
          />
        </Link>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Welcome back
            </h1>
            <p>Please enter your login details below</p>
            <form
              className="space-y-4 md:space-y-6"
              action=""
              onSubmit={SignIn}
            >
              <div>
                <label htmlFor="email" className="input-label">
                  Email <span className="text-[#FF4B00]">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="input-control"
                  placeholder="name@domain.com"
                  onChange={handleInputChange}
                  value={userDetails.email.trim()}
                  required
                />
              </div>
              <div>
                <label htmlFor="password" className="input-label">
                  Password <span className="text-[#FF4B00]">*</span>
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="input-password"
                    name="password"
                    onChange={handleInputChange}
                    value={userDetails.password}
                  />
                  <div
                    className="absolute inset-y-0 end-3 flex items-center ps-3.5 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <Icon
                      path={showPassword ? mdiEyeOff : mdiEye}
                      vertical
                      size={1}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="ml-1 text-sm">
                    <Link
                      to="/forgot-password"
                      className="text-sm font-medium text-[#054B98] hover:underline"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
              </div>

              <Button content="LOGIN" type="submit" />
              <p className="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
                Need an account?{" "}
                <Link
                  to="/register"
                  className="font-medium text-[#054B98] hover:underline font-mont"
                >
                  Sign up
                </Link>
              </p>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
                This site is protected by reCAPTCHA and the Google
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default observer(Login);
