/* eslint-disable eqeqeq */
/* eslint-disable react/style-prop-object */
import React, { useContext, useState } from "react";
import Select from "react-select";
import { StoreContext } from "../../mobx stores/RootStore";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import "./styles.css";
import { CustomFlowbiteTheme, Tabs } from "flowbite-react";
import PayVendorModal from "../../components/form/PayVendorModal";
import PayAJetCharterModal from "../../components/form/PayAJetCharter";
import PayASupplierModal from "../../components/form/PayASupplierModal";
import PayForMeModal from "../../components/form/PayForMeModal";
import BuyAJetModal from "../../components/form/BuyAJet";

const options = [
  {
    value: "USD",
    label: "USD",
    imgSrc: "../../assets/icons/usa.png",
  },
  { value: "EUR", label: "EUR", imgSrc: "../../assets/icons/eur.png" },
  {
    value: "GBP",
    label: "GBP",
    imgSrc: "../../assets/icons/uk.png",
  },
  {
    value: "CAD",
    label: "CAD",
    imgSrc: "../../assets/icons/cad.png",
  },
];

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    font: "14px Montserrat",
    fontWidth: "400",
    width: "100%",
    height: "auto",
  }),
  control: (provided: any) => ({
    ...provided,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    font: "14px Montserrat",
    fontWidth: "400",
  }),
};
interface FlagOptionProps {
  innerProps: React.HTMLAttributes<HTMLDivElement>;
  label: string;
  data: { imgSrc: string; value: string };
}

const FlagOption: React.FC<FlagOptionProps> = ({ innerProps, label, data }) => (
  <div
    {...innerProps}
    style={{
      display: "flex",
      alignItems: "center",
      width: "100%",
      paddingRight: "2px",
    }}
  >
    <div className="overflow-hidden flex flex-row hover:bg-gray-400  pb-1 pl-0 cursor-pointer">
      <img src={data.imgSrc} alt={label} className="h-auto my-auto pl-2 py-2" />
      <p className="ml-2 py-2">{data.value}</p>
    </div>
  </div>
);

const Wallet = () => {
  const { profileStore } = useContext(StoreContext);
  const { profile } = profileStore;
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [showOtherBalances, setShowOtherBalances] = useState(false);

  const handleSelect = (countryCode: string) => {
    setSelectedCountry(countryCode);
    setShowOtherBalances(countryCode !== "USD");
  };

  const customTheme: CustomFlowbiteTheme["tabs"] = {
    base: "flex flex-col md:flex-row gap-2",
    tablist: {
      base: "flex flex-column space-y space-y-4 text-center",
      styles: {
        fullWidth: "w-auto min-w-[250px] text-sm font-medium flex-col rounded-none md:mr-10",
      },
      tabitem: {
        base: "flex items-center justify-center p-4 rounded-t-lg text-sm font-medium ",
        styles: {
          fullWidth: {
            base: "ml-0 first:ml-0 w-full rounded-none flex",
            active: {
              on: "p-4 text-white bg-[#054B98] active rounded-none",
              off: "bg-white text-[#054B98] rounded-none",
            },
          },
        },
      },
    },
    tabitemcontainer: {
      base: "",
      styles: {
        fullWidth: "",
      },
    },
    tabpanel: "py-3",
  };

  return (
    <div className="bg-[#D9DBE9] h-screen sm:ml-64 overflow-x-auto">
      <div className="p-4 rounded-lg">
        {/* {loadingData && <Spinner />} */}

        {/* NEW */}
        <section className="p-3 sm:p-5">
          <div className="mb-3 flex flex-col justify-between lg:flex-row space-y-5 md:gap-10 gap-3">
            <div className="flex items-center">
              <h1 className="font-mont text-[#054B98] font-bold w-[137px] text-4xl">
                Make Payment
              </h1>
            </div>
            {/* Balance */}
            <div className="flex flex-col w-full max-w-[355px] min-h-[120px] bg-[#FFF] rounded-[20px] shadow inset-0 box-shadow-[0px_4px_40px_0px_rgba(0,0,0,0.25)] p-4 gap-3 xl:mr-[20px]">
              <h1 className="text-[#D9D9D9] font-mont font-bold text-lg">
                Balance
              </h1>
              <div className="flex flex-col md:flex-row gap-6 justify-between">
                <div className="w-full">
                  {showOtherBalances || selectedCountry === "USD" ? (
                    <>
                      {selectedCountry === "USD" && (
                        <h1 className="text-[#000] font-mont font-bold text-3xl place-self-end text-end">
                          $
                          {toJS(profile?.data)?.usd != undefined
                            ? Number(toJS(profile?.data)?.usd).toFixed(4)
                            : "0.0000"}
                        </h1>
                      )}
                      {selectedCountry === "EUR" && (
                        <h1 className="text-[#000] font-mont font-bold text-3xl place-self-end text-end">
                          €
                          {toJS(profile?.data)?.euro != undefined
                            ? Number(toJS(profile?.data)?.euro).toFixed(4)
                            : "0.0000"}
                        </h1>
                      )}
                      {selectedCountry === "CAD" && (
                        <h1 className="text-[#000] font-mont font-bold text-3xl place-self-end text-end">
                          CA$
                          {toJS(profile?.data)?.cad != undefined
                            ? Number(toJS(profile?.data)?.cad).toFixed(4)
                            : "0.0000"}
                        </h1>
                      )}
                      {selectedCountry === "GBP" && (
                        <h1 className="text-[#000] font-mont font-bold text-3xl place-self-end text-end">
                          £
                          {toJS(profile?.data)?.gbp != undefined
                            ? Number(toJS(profile?.data)?.gbp).toFixed(4)
                            : "0.0000"}
                        </h1>
                      )}
                    </>
                  ) : (
                    <h1 className="text-[#000] font-mont font-bold text-3xl place-self-end text-end">
                      $
                      {toJS(profile?.data)?.usd != undefined
                        ? Number(toJS(profile?.data)?.usd).toFixed(4)
                        : "0.0000"}
                    </h1>
                  )}
                </div>
                <Select
                  options={options}
                  components={{ Option: FlagOption }}
                  // styles={customStyles}
                  onChange={(option: any) => handleSelect(option.value)}
                />
              </div>
            </div>
            {/* Your Transfer */}
            <div className="flex flex-col w-full max-w-[405px] md:px-4 px-4 min-h-[120px] bg-[#FFF] rounded-[20px] shadow inset-0 box-shadow-[0px_4px_40px_0px_rgba(0,0,0,0.25)] p-4 gap-3">
        
                <h1 className="text-[#D9D9D9] font-mont font-bold text-lg">
                  You Transfer
                </h1>
                <div className="flex flex-row w-full gap-6">
          
                  <h1 className="font-mont text-[#000] font-bold md:text-3xl text-2xl">
                    100.00
                  </h1>

                    <h1 className="text-[#C48827] md:text-3xl text-2xl font-mont font-light ">
                      |
                    </h1>
                    <h1 className="font-mont text-[#000] font-bold md:text-3xl text-2xl">
                      US $
                    </h1>
              </div>
            </div>
          </div>

        </section>

              
          <Tabs
            aria-label="Full width tabs"
            style="fullWidth"
            theme={customTheme}
          >
            <Tabs.Item active title="Pay a Jet Charter">
              <PayAJetCharterModal />
            </Tabs.Item>
            <Tabs.Item title="Buy a Jet">
              <BuyAJetModal />
            </Tabs.Item>
            <Tabs.Item title="Pay for Me">
              <PayForMeModal />
            </Tabs.Item>
            <Tabs.Item title="Pay a Supplier">
              <PayASupplierModal />
            </Tabs.Item>
            <Tabs.Item title="Pay a Vendor">
              <PayVendorModal />
            </Tabs.Item>
          </Tabs>
      </div>
    </div>
  );
};

export default observer(Wallet);
