import React, { useContext, useEffect, useState } from "react";
import Spinner from "../Spinner";
import { StoreContext } from "../../mobx stores/RootStore";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import PhoneInput from "react-phone-input-2";

const ProfileUpdate = () => {
  const [isEditable, setIsEditable] = useState(false);
  const { profileStore } = useContext(StoreContext);
  const { profile, submitting, message } = profileStore;
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    rcNumber: "",
    accountType: "",
    bvn: "",
    nin: "",
  });

  const profileDetails = toJS(profile?.data);

  useEffect(() => {
    setUserDetails({
      email:
        profile && profileDetails?.email === undefined
          ? ""
          : profileDetails?.email,
      firstName:
        profile && profileDetails?.firstName === undefined
          ? ""
          : profileDetails?.firstName,
      lastName:
        profile && profileDetails?.lastName === undefined
          ? ""
          : profileDetails?.lastName,
      phone:
        profile && profileDetails?.phone === undefined
          ? ""
          : profileDetails?.phone,
      rcNumber:
        profile && profileDetails?.rcNumber === undefined
          ? ""
          : profileDetails?.rcNumber,
      accountType:
        profile && profileDetails?.accountType === undefined
          ? ""
          : profileDetails?.accountType,
      bvn:
        profile && profileDetails?.bvn === undefined ? "" : profileDetails?.bvn,
      nin:
        profile && profileDetails?.nin === undefined ? "" : profileDetails?.nin,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const handleToggle = () => {
    setIsEditable(!isEditable);
  };

  const handleInputChange = (e: any) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setUserDetails((prevDetails: any) => {
      return {
        ...prevDetails,
        [element.name]:
          element.name === "email"
            ? element.value.trim().toLowerCase()
            : element.value,
      };
    });
  };

  const updateProfile = (e: any) => {
    e.preventDefault();
    profileStore.updateProfile(userDetails);
  };

  useEffect(() => {
    if (
      message.type === "success" &&
      message.msg === "Profile updated successfully"
    ) {
      setIsEditable(false);
    }
  }, [message.type, message.msg]);

  return (
    <section className="flex-1 flex-col md:flex-row h-[calc(100vh_-_240px)] md:w-[calc(100vw_-_480px)]">
      {submitting && <Spinner />}
      <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16 bg-white">
        <h2 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-center">
          Profile Details
        </h2>
        <form onSubmit={updateProfile}>
          <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
            <div className="sm:col-span-2">
              {profileDetails?.accountType === "individual" ? (
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Full Name
                </label>
              ) : (
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Business Name
                </label>
              )}
              <input
                type="text"
                name="name"
                disabled={true}
                value={`${profileDetails?.firstName} ${profileDetails?.lastName}`}
                id="name"
                className="input-control form-control border-0"
              />
            </div>
            <div className="sm:col-span-2">
              {profileDetails?.accountType === "corporate" ? (
                <>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    RC Number
                  </label>
                  <input
                    type="text"
                    name="rcNumber"
                    disabled={!isEditable}
                    defaultValue={profileDetails?.rcNumber}
                    onChange={handleInputChange}
                    id="rcNumber"
                    className={
                      isEditable
                        ? "input-control form-control border-1 bg-white"
                        : "input-control form-control border-0"
                    }
                  />
                </>
              ) : null}
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                disabled={!isEditable}
                defaultValue={profileDetails?.email}
                onChange={handleInputChange}
                className={
                  isEditable
                    ? "input-control form-control border-1 bg-white"
                    : "input-control form-control border-0"
                }
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Phone Number
              </label>
              <PhoneInput
                country={"ng"}
                disabled={!isEditable}
                containerClass="my-container-className"
                inputClass="my-input-className"
                containerStyle={{
                  width: "100%",
                  height: "40px",
                }}
                countryCodeEditable={false}
                inputStyle={{
                  width: "100%",
                  height: "40px",
                  border: isEditable ? "1px solid #ccc" : "0px solid #ccc",
                  backgroundColor: isEditable ? "#fff" : "#f9f9f9",
                }}
                onChange={(e: string) =>
                  setUserDetails((prevDetails: any) => {
                    return { ...prevDetails, phone: e };
                  })
                }
                value={userDetails?.phone}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="bvn"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                BVN
              </label>
              <input
                type="number"
                name="bvn"
                disabled={
                  profileDetails?.bvn === "string" || profileDetails?.bvn === ""
                    ? !isEditable
                    : true
                }
                defaultValue={profileDetails?.bvn}
                id="bvn"
                onChange={handleInputChange}
                className={
                  (profileDetails?.bvn === "" && isEditable) ||
                  (profileDetails?.bvn === "string" && isEditable)
                    ? "input-control form-control border-1 bg-white"
                    : "input-control form-control border-0 bg-gray-50"
                }
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="nin"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                NIN
              </label>
              <input
                type="number"
                name="nin"
                id="nin"
                disabled={
                  profileDetails?.nin === "" || profileDetails?.nin === "string"
                    ? !isEditable
                    : true
                }
                defaultValue={profileDetails?.nin}
                onChange={handleInputChange}
                className={
                  (profileDetails?.nin === "" && isEditable) ||
                  (profileDetails?.nin === "string" && isEditable)
                    ? "input-control form-control border-1 bg-white"
                    : "input-control form-control border-0 bg-gray-50"
                }
              />
            </div>
          </div>
          {isEditable ? (
            <div className="flex gap-4">
              <button
                type="submit"
                className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
                // onClick={handleToggle}
              >
                Save Update
              </button>
              <button
                type="button"
                className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-orange-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
                onClick={handleToggle}
              >
                Cancel Update
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
              onClick={handleToggle}
            >
              Edit Profile
            </button>
          )}
        </form>
      </div>
    </section>
  );
};

export default observer(ProfileUpdate);
