// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
     .container {
        display: flex;
        flex-direction: column;
     }
  }`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/styles.css"],"names":[],"mappings":"AAAA;KACK;QACG,aAAa;QACb,sBAAsB;KACzB;EACH","sourcesContent":["@media only screen and (max-width: 600px) {\n     .container {\n        display: flex;\n        flex-direction: column;\n     }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
