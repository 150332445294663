
import React, { useRef, useEffect } from "react";
import Navbar from "../../widgets/navbar/Navbar";
import Footer from "../../widgets/footer/Footer";
import ServiceSection from "../../components/landing-page/ServiceSection";
import ContactForm from "../../components/landing-page/ContactForm";
import FaqSection from "../../components/landing-page/FaqSection";
import { useLocation } from "react-router-dom";

const Contact = () => {


  return (
    <div>
      <Navbar />
      <ServiceSection content="Contact us" />
      <ContactForm />
      <FaqSection/>
      <Footer />
    </div>
  );
};

export default Contact;
