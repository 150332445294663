import { Button } from "flowbite-react";
import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { StoreContext } from "../../mobx stores/RootStore";
import { observer } from "mobx-react-lite";
import Spinner from "../Spinner";

const CreatePin = () => {
  const { profileStore } = useContext(StoreContext);
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [pinToggle, setPinToggle] = useState(false);
  const [pin, setPin] = useState({
    newPin: "string",
    confirmPin: "string",
  });

  const { submitting, message } = profileStore;

  useEffect(() => {
    setPin({
      newPin,
      confirmPin,
    });
  }, [newPin, confirmPin]);

  const handleCreatePin = (e: FormEvent) => {
    e.preventDefault();

    if (
      pin.newPin !== pin.confirmPin ||
      pin.newPin === "" ||
      pin.confirmPin === ""
    ) {
      toast.error("Pins do not match");
      return;
    }
    profileStore.createPin(pin);
  };

  const handlePinToggle = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    setPinToggle(element.checked);
  };

  const validateNumber = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  return (
    <section className="bg-gray-50">
    <div className="flex flex-col items-center justify-center lg:py-0 ">
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md md:p-10 p-6 space-y-4 md:space-y-6 sm:p-8">
    <form onSubmit={handleCreatePin} className="flex justify-center items-center">
       {submitting && <Spinner />}
      <div className="py-5 px-4 rounded mt-4">
        <div className="row">
          <div className="col-md-12 input-box">
            <label className="mb-1">
              Enter Pin
              <span style={{ color: "red" }}> *</span>
            </label>
            <OtpInput
              value={newPin}
              onChange={setNewPin}
              numInputs={4}
              inputStyle="pin-style"
              containerStyle="pin-container-style"
              inputType={pinToggle ? "tel" : "password"}
              shouldAutoFocus={true}
              renderInput={(props) => (
                <input onKeyPress={validateNumber} {...props} />
              )}
            />
          </div>
          <div className="col-md-12 input-box">
            <div>
              <label className="mb-1">
                Confirm Pin
                <span style={{ color: "red" }}> *</span>
              </label>
              <OtpInput
                value={confirmPin}
                onChange={setConfirmPin}
                numInputs={4}
                inputStyle="pin-style"
                containerStyle="pin-container-style"
                inputType={pinToggle ? "tel" : "password"}
                shouldAutoFocus={false}
                renderInput={(props) => (
                  <input onKeyPress={validateNumber} {...props} />
                )}
              />
            </div>
          </div>
          <div className="flex gap-2 justify-center items-center mb-3">
                    <input
                      type="checkbox"
                      onChange={handlePinToggle}
                      className="btn-switch"
                      name="showPin"
                      id="show-pin"
                    />
                    <label htmlFor="show-pin">Show pin</label>
                  </div>
        </div>
        {message.msg !== "" && (
          <div
            className={
              message.type === "success"
                ? " alert alert-success"
                : "alert alert-danger"
            }
            role="alert"
          >
          </div>
        )}
                <Button
                disabled={submitting}
                className="flex-1 w-full"
                type="submit"
              >
               {submitting ? "Creating..." : "Create Pin"}
              </Button>
      </div>
    </form>
    </div>
    </div>
    </section>
  );
};

export default observer(CreatePin);
