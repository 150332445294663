import React from "react";
import { useMediaQuery } from "react-responsive";

const ResponsiveTable = ({ data }: any) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className="table-container lg:px-10 lg:py-16 px-0 py-8 text-center ">
      <table className={`table ${isTabletOrMobile ? "mobile-table" : ""}`}>
        <thead>
          <tr>
            <th
              className="border-b border-[#AFB6E5] text-start text-[#054B98] font-medium font-mont text-lg"
              style={{
                paddingLeft: "26px",
                alignItems: "center",
              }}
            >
              Currency
            </th>
            <th className="border-b border-[#AFB6E5] p-2 text-justify text-[#054B98] font-medium font-mont text-lg">
              Buying
            </th>
            <th className="border-b border-[#AFB6E5] p-2 text-justify text-[#054B98] font-medium font-mont text-lg">
              Selling
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item: any, i: number) => (
            <tr key={i}>
              <td>
                <span className="flex gap-4 justify-start items-center">
                  <img
                    src={item?.imgSrc}
                    alt={item.currency}
                    className="w-[50px] h-[50px] rounded-full my-auto ml-4"
                  />
                  {item.currency}
                </span>
              </td>
              <td className="text-justify">
                N{" "}
                {new Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: Number(item.buy) <= 1 ? 2 : 0,
                  maximumFractionDigits: Number(item.buy) <= 1 ? 2 : 0,
                }).format(Number(item.buy))}
              </td>
              <td className="text-justify">
                N{" "}
                {new Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: Number(item.sell) <= 1 ? 2 : 0,
                  maximumFractionDigits: Number(item.sell) <= 1 ? 2 : 0,
                }).format(Number(item.sell))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResponsiveTable;
