import React, { useEffect, useState } from "react";
import "./modal.css";
import { Button, Modal } from "flowbite-react";
import BaseDirectories from "../../base directories/BaseDirectories";
import { observer } from "mobx-react-lite";
type ModalProps = {
  openModal: boolean | any;
  setOpenModal: () => void;
  resultCurrency: number | null;
  selectedAmount: number | null;
  convertedAmount: number | null;
  fromCurrency: any | null;
  toCurrency: any | null;
};

const ModalView = ({
  openModal,
  setOpenModal,
  resultCurrency,
  selectedAmount,
  fromCurrency,
  toCurrency,
  rates,
}: any) => {
  return (
    <Modal
      show={openModal}
      onClose={() => setOpenModal(false)}
      size="md"
      popup
      // position="bottom-center"
      className="pt-28"
    >
      <Modal.Header>
        <div
          className="flex flex-row justify-center items-center gap-3"
          style={{}}
        >
          <p className="w-full text-bold text-centerfont-[700]">
            Conversion Summary{" "}
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-1 md:p-1">
          <p className="text-sm font-normal text-gray-500 dark:text-gray-400 text-center">
            Transfer money to the wallet id below to fund your wallet
          </p>
          <ul className="my-2 space-y-1.5">
            <li className="flex items-center p-1 md:p-3 md:text-base text-sm font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
              <span className="flex-1 ms-3 whitespace-nowrap justify-start">
                From
              </span>
              <span className="inline-flex justify-end text-right items-end px-2 py-0.5 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">
                {fromCurrency}
              </span>
            </li>

            <li className="flex items-center p-1 md:p-3 md:text-base text-sm font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
              <span className="flex-1 ms-3 whitespace-nowrap">To</span>
              <span
                style={{ textTransform: "capitalize" }}
                className="inline-flex justify-end text-right items-end px-2 py-0.5 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400"
              >
                {toCurrency}
              </span>
            </li>


            <li className="flex items-center p-1 md:p-3 md:text-base text-sm font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
              <span className="flex-1 ms-3 whitespace-nowrap">Rates</span>
              <span
                style={{ textTransform: "capitalize" }}
                className="inline-flex justify-end text-right items-end px-2 py-0.5 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400"
              >
                {rates && rates}
              </span>
            </li>

            <li className="flex items-center p-1 md:p-3 md:text-base text-sm font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
              <span className="flex-1 ms-3 whitespace-nowrap">Amount</span>
              <span className="inline-flex justify-end text-right items-end px-2 py-0.5 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">
                {fromCurrency !== "NGN"
                  ? new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 6,
                    }).format(Number(selectedAmount))
                  : new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(Number(selectedAmount))}
              </span>
            </li>

            <li className="flex items-center p-1 md:p-3 md:text-base text-sm font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
              <span className="flex-1 ms-3 whitespace-nowrap">Result</span>
              <span className="inline-flex justify-end text-right items-end px-2 py-0.5 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">
                {fromCurrency !== "NGN"
                  ? new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(Number(resultCurrency))
                  : new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(Number(resultCurrency))}
              </span>
            </li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color=""
          className="text-white bg-[#054B98] hover:bg-[#223b56] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={() => setOpenModal(false)}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default observer(ModalView);
