import { initFlowbite } from "flowbite";
import React, { useEffect } from "react";
import ProfileUpdate from "../../components/settings/ProfileUpdate";
import PinManagement from "../../components/settings/PinManagement";
import PasswordManagement from "../../components/settings/PasswordManagement";
import NotificationsManagement from "../../components/settings/NotificationsManagement";
import { CustomFlowbiteTheme } from "flowbite-react";
import { HiAdjustments, HiClipboardList, HiUserCircle, HiCog } from "react-icons/hi";
import { Tabs } from "flowbite-react";

const Password = () => {
  useEffect(() => {
    initFlowbite();
  }, []);

  const customTheme: CustomFlowbiteTheme["tabs"] = {
    base: "flex md:flex-row flex-col gap-2",
    tablist: {
      base: "flex md:w-[150px] md:flex-col flex-row justify-center item-center md:justify-start md:gap-6 gap-2 text-center mt-4",
      styles: {
        pills:
          "flex-wrap font-medium text-sm text-gray-500 dark:text-gray-400 space-x-2",
      },
      tabitem: {
        base: "flex md:justify-end justify-center p-2 md:p-3 rounded-t-lg text-sm bg-gray-200 font-medium first:ml-0 ocus:ring-4 focus:ring-gold-300 focus:outline-none",
        styles: {
          pills: {
            base: "",
            active: {
              on: "rounded-lg bg-[#C48827] text-white",
              off: "rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white",
            },
          },
        },
        icon: "mr-2 h-5 w-5",
      },
    },
    tabitemcontainer: {
      base: "",
      styles: {
        pills: "",
      },
    },
    tabpanel: "py-3",
  };

  return (
    <div className="p-4 sm:ml-64">
      <div className="mb-4 md:flex">
        <Tabs aria-label="Tabs with pills" style="pills" theme={customTheme}>
          <Tabs.Item active title="Pin" icon={HiAdjustments}>
            <div id="default-tab-content" className="min-h-[400px] bg-gray-50">
              <div className="p-6 bg-gray-50 text-medium text-gray-500 rounded-lg w-full">
                <PinManagement />
              </div>
            </div>
          </Tabs.Item>
          <Tabs.Item active title="Profile" icon={HiUserCircle}>
            <div id="default-tab-content" className="min-h-[400px] bg-gray-50">
              <div className="p-6 bg-gray-50 text-medium text-gray-500 rounded-lg w-full">
                <ProfileUpdate />
              </div>
            </div>
          </Tabs.Item>
          <Tabs.Item title="Password" icon={HiCog}>
            <div id="default-tab-content" className="bg-gray-50">
              <div className="p-2 pt-8 bg-gray-50 text-medium text-gray-500 rounded-lg w-full">
                <PasswordManagement />
              </div>
            </div>
          </Tabs.Item>
          <Tabs.Item title="Notifications" icon={HiClipboardList}>
            <div id="default-tab-content" className="min-h-[400px] bg-gray-50">
              <div className="p-6 bg-gray-50 text-medium text-gray-500 rounded-lg w-full">
                <NotificationsManagement />
              </div>
            </div>
          </Tabs.Item>
        </Tabs>
      </div>
    </div>
  );
};

export default Password;
