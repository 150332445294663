import React, { useContext, useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import LandingPage from "../pages/landingPage/LandingPage";
import Login from "../pages/authentication/Login";
import Register from "../pages/authentication/Register";
import ForgotPassword from "../pages/authentication/ForgotPassword";
import ChangePassword from "../pages/authentication/ChangePassword";
import ScrollToTop from "../components/ui/ScrollToTop";
import { onAuthStateChangedListener, auth } from "../firebase/firebase";
import { SetAllAccessTokens, StoreContext } from "../mobx stores/RootStore";
import PrivateRoute from "../components/PrivateRoute";
import ProtectedRoute from "../components/ProtectedRoute";
import { observer } from "mobx-react-lite";
import AboutUs from "../pages/landingPage/AboutUs";
import ErrorRoute from "../pages/error/ErrorRoute";
import Services from "../pages/landingPage/Services";
import Contact from "../pages/landingPage/Contact";
import BaseDirectories from "../base directories/BaseDirectories";
import { LogOut } from "../utils/AuthFirestore";

const MainRoutes = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { profileStore, authStore, walletStore } = useContext(StoreContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, setUser] = useState<null | any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeUser, setActiveUser] = React.useState(
    false || window.sessionStorage.getItem("userStatus") === "true"
  );
  const logoutTimerRef: any = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener(async (res: any) => {
      if (res) {
        res?.getIdToken(true).then((idToken: string) => {
          window.sessionStorage.setItem("accessToken", idToken);
          SetAllAccessTokens(idToken);
          try {
            authStore.setUser(res);
            profileStore.getProfile();
            walletStore.getWalletWithoutLoading(); // to call when on dashboard and disable from here
          } catch (error) {
          } finally {
          }
        });
        if (res) {
          if (res.emailVerified) {
            window.sessionStorage.setItem("userStatus", "true");
            setActiveUser(true);
          }
        } else {
          window.sessionStorage.setItem("userStatus", "false");
          setActiveUser(false);
        }
        return res ? setUser(res) : setUser(null);
      }
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkAndRenewToken = async () => {
      const userData: any = auth.currentUser;
      if (userData) {
        try {
          await userData.getIdToken(true).then((idToken: string) => {
            window.sessionStorage.setItem("accessToken", idToken);
            SetAllAccessTokens(idToken);
          });
          // console.debug('Token refreshed successfully.');
        } catch (err) {
          // console.debug('Error refreshing token:', err);
        }
      }
    };
    const intervalCheck = setInterval(checkAndRenewToken, 600000);

    return () => clearInterval(intervalCheck);
  }, []);


  useEffect(() => {

    const resetLogoutTimer = () => {
      if (logoutTimerRef.current) {
        clearTimeout(logoutTimerRef.current);
      }

      // Set a new timer for 2 minutes (120000 milliseconds)
      const newTimer = setTimeout(logoutUser, 10 * 60 * 1000);
      logoutTimerRef.current = newTimer;
    };

    const logoutUser = async () => {
      if (auth.currentUser !== null) {
        // Perform logout logic here
        const res = await LogOut();
        if (res.error === false) {
          window.sessionStorage.clear();
          window.localStorage.clear();
          window.sessionStorage.removeItem("authUser");
          window.location.href = `${BaseDirectories.BASE_URL}/login`;
        } else {
        }
      }
    };

    const handleUserActivity = () => {
      resetLogoutTimer();
    };

    // Attach event listeners for user activity
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    // Set an initial timer
    resetLogoutTimer();

    // Cleanup event listeners and timer on component unmount
    return () => {
      if (logoutTimerRef.current) {
        clearTimeout(logoutTimerRef.current);
      }

      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, []);

  
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/login"
            element={
              <ProtectedRoute>
                <Login />
              </ProtectedRoute>
            }
          />
          <Route
            path="/register"
            element={
              <ProtectedRoute>
                <Register />
              </ProtectedRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <ProtectedRoute>
                <ForgotPassword />
              </ProtectedRoute>
            }
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/*" element={<ErrorRoute />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default observer(MainRoutes);
