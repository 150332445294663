/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";

const ServiceSection = ({content}: any) => {
  return (
    <section className="bg-center bg-no-repeat bg-blend-multiply">
      <div className="service-section" >
        <div className="px-4 mx-auto max-w-screen-xl text-start py-32 lg:py-56 md:py-32">
          <h1 className="mb-4 text-4xl font-[800] tracking-tight leading-none text-[#054B98] md:text-5xl lg:text-6xl">
            {content}
          </h1>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
