import { Tabs } from "flowbite-react";
import type { CustomFlowbiteTheme } from "flowbite-react";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import Icon from "@mdi/react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link, useNavigate } from "react-router-dom";
import BaseDirectories from "../../base directories/BaseDirectories";
import { useContext, useEffect, useState } from "react";
import Button from "../../components/ui/Button";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React from "react";
import Spinner from "../../components/Spinner";
import { StoreContext } from "../../mobx stores/RootStore";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";

const Register = () => {
  const { authStore } = useContext(StoreContext);
  const { loading, message } = authStore;
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    rcNumber: "",
    accountType: "",
    bvn: "string",
    nin: "",
  });

  const [coporateDetails, setCoporateDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    rcNumber: "",
    accountType: "",
    bvn: "",
    nin: "",
  });

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setUserDetails((prevDetails: any) => {
      return {
        ...prevDetails,
        [element.name]:
          element.name === "email"
            ? element.value.trim().toLowerCase()
            : element.value,
      };
    });
  };

  const handleCoporateInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setCoporateDetails((prevDetails: any) => {
      return {
        ...prevDetails,
        [element.name]:
          element.name === "email"
            ? element.value.trim().toLowerCase()
            : element.value,
      };
    });
  };

  const customTheme: CustomFlowbiteTheme["tabs"] = {
    base: "flex flex-col gap-2",
    tablist: {
      base: "flex text-center",
      styles: {
        fullWidth: "w-full text-sm font-medium grid grid-flow-col rounded-none",
      },
      tabitem: {
        base: "flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:ring-4 focus:ring-white focus:outline-none",
        styles: {
          fullWidth: {
            base: "ml-0 first:ml-0 w-full rounded-none flex",
            active: {
              on: "p-4 text-[#FFB01D] font-[500] md:text-[24px] text-md bg-white border-0 border-white active rounded-none",
              off: "text-[#054B98] md:text-[24px] text-md font-[500] bg-white rounded-none",
            },
          },
        },
      },
    },
    tabitemcontainer: {
      base: "",
      styles: {
        default: "",
        underline: "",
        pills: "",
        fullWidth: "",
      },
    },
    tabpanel: "py-3",
  };

  const personalSignUp = (e: any) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    userDetails.phone = `+${userDetails.phone}`;
    userDetails.accountType = "individual";
    console.log(userDetails);
    authStore.CreateUser(userDetails);

    runInAction(() => {
      authStore.setError = (error: any) => {
        if (error.message === "Firebase: Error (auth/email-already-in-use).") {
          toast.error(
            "Email already in use. Please use a different email then try again!"
          );
          return;
        }
        if (
          error.message ===
          "Firebase: Password should be at least 6 characters (auth/weak-password)."
        ) {
          toast.error("Password should be at least 6 characters");

          return;
        }

        if (error.message === "Firebase: Error (auth/invalid-email).") {
          toast.error(
            "Invalid email. Please use a valid email then try again!"
          );

          return;
        }
        if (error.message === "Firebase: Error (auth/weak-password).") {
          toast.error("Weak password.");

          return;
        } else {
          toast.error(
            "We are unable to process your form submission at this time. Please try again later!"
          );

          return;
        }
      };
    });
  };

  useEffect(() => {
    if (message.type === "success" && message.msg === "Creation successful!") {
      // navigate("/email_verification");
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.msg, message.type]);

  const coperateSignUp = (e: any) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    coporateDetails.accountType = "corporate";
    coporateDetails.phone = `+${coporateDetails.phone}`;
    authStore.CreateUser(coporateDetails);

    // runInAction(() => {
    //   authStore.setSuccess = () => {
    //     // navigate("/email_verification");
    //     navigate("/login");
    //   };
    // });

    runInAction(() => {
      authStore.setError = (error: any) => {
        if (error.message === "Firebase: Error (auth/email-already-in-use).") {
          toast.error(
            "Email already in use. Please use a different email then try again!"
          );
          return;
        }
        if (
          error.message ===
          "Firebase: Password should be at least 6 characters (auth/weak-password)."
        ) {
          toast.error("Password should be at least 6 characters");

          return;
        }

        if (error.message === "Firebase: Error (auth/invalid-email).") {
          toast.error(
            "Invalid email. Please use a valid email then try again!"
          );

          return;
        }
        if (error.message === "Firebase: Error (auth/weak-password).") {
          toast.error("Weak password.");

          return;
        } else {
          toast.error(
            "We are unable to process your form submission at this time. Please try again later!"
          );

          return;
        }
      };
    });
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 h-dvh py-16">
      {loading && <Spinner />}
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 ">
        <Link to="/" className="flex items-center mb-4">
          <img
            src={`${BaseDirectories.LOGOS_DIR}/aeroconvert-logo.svg`}
            alt="Aeroconvert Logo"
          />
        </Link>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-[24px] dark:text-white text-center">
              Create your account
            </h1>
            <p className="text-center">Please provide your credentials below</p>
            {/* Tabs */}
            {/* Tab Content */}
            <Tabs
              aria-label="Full width tabs"
              // eslint-disable-next-line react/style-prop-object
              style="fullWidth"
              theme={customTheme}
            >
              <Tabs.Item
                active
                title="Personal"
                className="inline-block p-4 border-b-2 rounded-t-lg text-[#FFB01D] text-[24px]"
              >
                <form
                  className="space-y-4 md:space-y-6"
                  action=""
                  onSubmit={personalSignUp}
                >
                  <div>
                    <label htmlFor="firstName" className="input-label">
                      First name <span className="text-[#FF4B00]">*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className="input-control"
                      value={userDetails.firstName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="lastName" className="input-label">
                      Last name <span className="text-[#FF4B00]">*</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      className="input-control"
                      value={userDetails.lastName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                    <div className="w-full">
                      <label
                        htmlFor="bvn"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        BVN<span className="text-[#FF4B00]">*</span>
                      </label>
                      <input
                        type="number"
                        name="bvn"
                        id="bvn"
                        className="input-control"
                        value={userDetails.bvn}
                        onChange={handleInputChange}
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="nin"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        NIN<span className="text-[#FF4B00]">*</span>
                      </label>
                      <input
                        type="number"
                        name="nin"
                        id="nin"
                        className="input-control"
                        value={userDetails.nin}
                        onChange={handleInputChange}
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="email" className="input-label">
                      Email <span className="text-[#FF4B00]">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="input-control"
                      value={userDetails.email.trim().toLowerCase()}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="input-label">
                      Phone Number <span className="text-[#FF4B00]">*</span>
                    </label>
                    <PhoneInput
                      country={"ng"}
                      containerClass="my-container-className"
                      inputClass="my-input-className"
                      containerStyle={{
                        width: "100%",
                        height: "40px",
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "40px",
                      }}
                      countryCodeEditable={false}
                      onChange={(e: string) =>
                        setUserDetails((prevDetails: any) => {
                          return { ...prevDetails, phone: e };
                        })
                      }
                      value={userDetails.phone}
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="input-label">
                      Password <span className="text-[#FF4B00]">*</span>
                    </label>
                    <div className="relative">
                      <input
                        name="password"
                        value={userDetails.password.trim()}
                        type={showPassword ? "text" : "password"}
                        onChange={handleInputChange}
                        className="input-password"
                      />
                      <div
                        className="absolute inset-y-0 end-3 flex items-center ps-3.5 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <Icon
                          path={showPassword ? mdiEyeOff : mdiEye}
                          vertical
                          size={1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="terms"
                        aria-describedby="terms"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        required
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="terms"
                        className="font-light text-gray-500 dark:text-gray-300"
                      >
                        I agree with the{" "}
                        <a
                          href="https://docs.google.com/document/d/1Gg-uvbCqS0dPo2lZSh1zDnteQphVVAeR/edit?usp=sharing&ouid=106690636404743294862&rtpof=true&sd=true"
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "#054b99" }}
                        >
                          Terms and Conditions
                        </a>{" "}
                        of Aeroconvert
                      </label>
                    </div>
                  </div>

                  <Button content="REGISTER NOW" type="submit" />
                  <p className="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
                    Already have an account?{" "}
                    <Link
                      to="/login"
                      className="font-medium text-[#054B98] hover:underline font-mont"
                    >
                      Login
                    </Link>
                  </p>
                </form>
              </Tabs.Item>
              <Tabs.Item
                title="Corporates"
                className="inline-block p-4 border-b-2 rounded-t-lg text-[#054B98] text-[24px]"
              >
                {/* Copporate Form */}
                <form
                  className="space-y-4 md:space-y-6"
                  action=""
                  onSubmit={coperateSignUp}
                >
                  <div>
                    <label htmlFor="firstName" className="input-label">
                      Business name <span className="text-[#FF4B00]">*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className="input-control"
                      value={coporateDetails.firstName}
                      onChange={handleCoporateInputChange}
                      required
                    />
                  </div>
                  <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                    <div className="w-full">
                      <label
                        htmlFor="bvn"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        BVN<span className="text-[#FF4B00]">*</span>
                      </label>
                      <input
                        type="number"
                        name="bvn"
                        id="bvn"
                        className="input-control"
                        value={coporateDetails.bvn}
                        onChange={handleCoporateInputChange}
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="nin"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        NIN<span className="text-[#FF4B00]">*</span>
                      </label>
                      <input
                        type="number"
                        name="nin"
                        id="nin"
                        className="input-control"
                        value={coporateDetails.nin}
                        onChange={handleCoporateInputChange}
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="rcNumber" className="input-label">
                      RC Number
                    </label>
                    <input
                      type="text"
                      name="rcNumber"
                      className="input-control"
                      value={coporateDetails.rcNumber}
                      onChange={handleCoporateInputChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="input-label">
                      Email <span className="text-[#FF4B00]">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="input-control"
                      value={coporateDetails.email}
                      onChange={handleCoporateInputChange}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="input-label">
                      Phone Number <span className="text-[#FF4B00]">*</span>
                    </label>
                    <PhoneInput
                      country={"ng"}
                      containerClass="my-container-className"
                      inputClass="my-input-className"
                      containerStyle={{
                        width: "100%",
                        height: "40px",
                      }}
                      countryCodeEditable={false}
                      inputStyle={{
                        width: "100%",
                        height: "40px",
                      }}
                      onChange={(e: string) =>
                        setCoporateDetails((prevDetails: any) => {
                          return { ...prevDetails, phone: e };
                        })
                      }
                      value={coporateDetails.phone}
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="input-label">
                      Password <span className="text-[#FF4B00]">*</span>
                    </label>
                    <div className="relative">
                      <input
                        name="password"
                        value={coporateDetails.password.trim()}
                        type={showPassword2 ? "text" : "password"}
                        onChange={handleCoporateInputChange}
                        className="input-password"
                      />
                      <div
                        className="absolute inset-y-0 end-3 flex items-center ps-3.5 cursor-pointer"
                        onClick={() => setShowPassword2(!showPassword2)}
                      >
                        <Icon
                          path={showPassword2 ? mdiEyeOff : mdiEye}
                          vertical
                          size={1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="terms"
                        aria-describedby="terms"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        required
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="terms"
                        className="font-light text-gray-500 dark:text-gray-300"
                      >
                        I agree with the{" "}
                        <a
                          href="https://docs.google.com/document/d/1Gg-uvbCqS0dPo2lZSh1zDnteQphVVAeR/edit?usp=sharing&ouid=106690636404743294862&rtpof=true&sd=true"
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "#054b99" }}
                        >
                          Terms and Conditions
                        </a>{" "}
                        of Aeroconvert
                      </label>
                    </div>
                  </div>
                  <Button content="REGISTER NOW" type="submit" />
                  <p className="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
                    Already have an account?{" "}
                    <Link
                      to="/login"
                      className="font-medium text-[#054B98] hover:underline font-mont"
                    >
                      Login
                    </Link>
                  </p>
                </form>
              </Tabs.Item>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
};

export default observer(Register);
