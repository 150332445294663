import { useContext, useEffect } from "react";
import { StoreContext } from "../../mobx stores/RootStore";
import CreatePin from "../form/CreatePin";
import UpdatePin from "../form/UpdatePin";
import { observer } from "mobx-react-lite";

const PinManagement = () => {
  const { profileStore } = useContext(StoreContext);
  const { isPinExist } = profileStore;
  const pinExist = isPinExist;


  useEffect(() => {
    profileStore.checkPin();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="flex-1 flex-col md:flex-row justigy-center items-center h-[calc(100vh_-_240px)] md:w-[calc(100vw_-_480px)] text-center">
      {pinExist ? <UpdatePin /> : <CreatePin />}
    </section>
  );
};

export default observer(PinManagement);
