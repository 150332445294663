/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import BaseDirectories from "../../base directories/BaseDirectories";

const AboutSection = () => {
  return (
    <section className="bg-center bg-no-repeat bg-blend-multiply">
        <div className="hero-section md:gap-8 gap-2 items-center md:grid md:grid-cols-2 flex flex-col">
          <div className="px-4 mx-auto max-w-screen-xl text-start py-8 lg:py-56 md:py-32">
            <h1 className="mb-4 text-4xl font-[800] tracking-tight leading-none text-[#054B98] md:text-5xl lg:text-6xl">
              Welcome to
            </h1>
            <h1 className="mb-4 text-4xl font-[800] tracking-tight leading-none text-[#054B98] md:text-5xl lg:text-6xl">
              Aeroconvert
            </h1>

            <div className="flex flex-row sm:flex-row justify-start space-x-4 md:mt-10 mt-14 max-w-[400px] text-left">
              <p className="md:text-[20px] text-md">
                A global leader in secure and efficient payment solutions for
                the aviation industry. At Aeroconvert, we understand the
                complexities and unique requirements involved in acquiring
                jetliners, and making high valued payments and we are dedicated
                to simplifying the payment process for our clients worldwide.
              </p>
            </div>
          </div>

        <img
            className="order-last px-2 msx-w-[553px] max-h-[420.5px]"
            src={`${BaseDirectories.IMAGES_DIR}/about_us.png`}
            alt="our vision"
          />
        </div>
    </section>
  );
};

export default AboutSection;
