/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import plug from "../../assets/icons/plug.png";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import ModalView from "./Modal";
import { StoreContext } from "../../mobx stores/RootStore";
import { toJS } from "mobx";

const options = [
  {
    value: "USD",
    label: "USD - US Dollar",
    imgSrc: "../../assets/icons/usa.png",
  },
  { value: "EUR", label: "EUR - Euro", imgSrc: "../../assets/icons/eur.png" },
  {
    value: "GBP",
    label: "GBP - Bristish Pound",
    imgSrc: "../../assets/icons/uk.png",
  },
  {
    value: "CAD",
    label: "CAD - Canadian Dollar",
    imgSrc: "../../assets/icons/cad.png",
  },
  {
    value: "AUD",
    label: "AUD - Australian Dollar",
    imgSrc: "../../assets/icons/aud.png",
  },
  {
    value: "JPY",
    label: "JPY - Japanese Yen",
    imgSrc: "../../assets/icons/jpy.png",
  },
  {
    value: "INR",
    label: "INR - Indian Rupee",
    imgSrc: "../../assets/icons/inr.png",
  },
  {
    value: "NGN",
    label: "NGN - Nigerian Naira",
    imgSrc: "../../assets/icons/naira.png",
  },
];

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    font: "14px Montserrat",
    fontWidth: "400",
  }),
  control: (provided: any) => ({
    ...provided,
    width: "100%",
    font: "14px Montserrat",
    fontWidth: "400",
    height: "45px",
  }),
};
const HeroSection = () => {
  const { walletStore } = useContext(StoreContext);
  const { currencyData } = walletStore;
  const [openModal, setOpenModal] = useState(false);
  const [resultCurrency, setResultCurrency] = useState<any>(null);
  const [exchangeCurrencyRate, setExchangeCurrencyRate] = useState<any>(null);

  const [rates, setRates] = useState<{
    USD: number | null;
    CAD: number | null;
    EUR: number | null;
    GBP: number | null;
  }>({
    USD: null,
    CAD: null,
    EUR: null,
    GBP: null,
  });

  const [selectedOption, setSelectedOption] = useState();
  const [selectedData, setSelectedData] = useState({
    from: "",
    to: "",
    amount: 0,
  });

  const handleSelectChange = (selectedOption: any, { name }: any) => {
    setSelectedData((prevData) => ({
      ...prevData,
      [name]: selectedOption.value,
    }));
  };

  const handleAmountChange = (e: React.FormEvent) => {
    const element = e.target as HTMLInputElement;
    setSelectedData({
      ...selectedData,
      amount: Number(element.value),
    });
  };

  useEffect(() => {
    walletStore.getCurrency();

    if (selectedData.from === "NGN") {
      const rates: any = toJS(walletStore.currencyData);
      if (rates && rates.length > 0) {
        const ngnToUsdRate = rates[0].USD[0].buy;
        const ngnToCadRate = rates[0].CAD[0].buy;
        const ngnToEurRate = rates[0].EUR[0].buy;
        const ngnToGbpRate = rates[0].GBP[0].buy;

        setRates({
          USD: ngnToUsdRate,
          CAD: ngnToCadRate,
          EUR: ngnToEurRate,
          GBP: ngnToGbpRate,
        });
      } else {
        console.log("Rates data structure is incorrect or undefined.");
      }
    }
  }, [walletStore, selectedData]);

  useEffect(() => {
    if (selectedData.amount && selectedData.from && selectedData.to) {
      if (selectedData.from === "NGN") {
        setResultCurrency(null);
        if (
          rates.USD !== null &&
          rates.CAD !== null &&
          rates.EUR &&
          rates.GBP !== null
        ) {
          const convertedUsdAmount = selectedData.amount / rates.USD;
          const convertedCadAmount = selectedData.amount / rates.CAD;
          const convertedEurAmount = selectedData.amount / rates.EUR;
          const convertedGbpAmount = selectedData.amount / rates.GBP;

          let selectedAmount: any;
          switch (selectedData.to) {
            case "USD":
              setExchangeCurrencyRate(rates.USD);
              selectedAmount = convertedUsdAmount;
              break;
            case "CAD":
              setExchangeCurrencyRate(rates.CAD);
              selectedAmount = convertedCadAmount;
              break;
            case "EUR":
              setExchangeCurrencyRate(rates.EUR);
              selectedAmount = convertedEurAmount;
              break;
            case "GBP":
              setExchangeCurrencyRate(rates.GBP);
              selectedAmount = convertedGbpAmount;
              break;
            default:
              selectedAmount = 0;
          }

          setResultCurrency(selectedAmount);
        } else {
          console.log("USD rate is null");
        }
      } else if (
        ["USD", "EUR", "CAD", "GBP"].includes(selectedData.from) &&
        ["USD", "EUR", "CAD", "GBP"].includes(selectedData.to) 
      ) {
        setResultCurrency(null);
          // Call api to set the rates
        axios("https://api.freecurrencyapi.com/v1/latest", {
          params: {
            apikey: process.env.REACT_APP_CURRENCY_API_KEY,
            base_currency: selectedData.from,
            currencies: selectedData.to,
          },
        })
          .then((response) => {
            setExchangeCurrencyRate(Number(response.data.data[selectedData.to]).toFixed(6));
            const result: number = Number(response.data.data[selectedData.to]) * Number(selectedData.amount)
            setResultCurrency(result);
            
          })
          .catch((error) => {
            // console.log(error);
          });
          // console.log(selectedData.amount);
          // console.log(rates);
          // console.log(selectedData);

        
      } else if (selectedData.to === "NGN") {
        setResultCurrency(null);
        if (
          rates.USD !== null &&
          rates.CAD !== null &&
          rates.EUR &&
          rates.GBP !== null
        ) {
          const convertedUsdAmount = selectedData.amount * rates.USD;
          const convertedCadAmount = selectedData.amount * rates.CAD;
          const convertedEurAmount = selectedData.amount * rates.EUR;
          const convertedGbpAmount = selectedData.amount * rates.GBP;

          let selectedAmount: any;
          switch (selectedData.from) {
              case "USD":
                selectedAmount = convertedUsdAmount;
                break;
              case "CAD":
                selectedAmount = convertedCadAmount;
                break;
              case "EUR":
                selectedAmount = convertedEurAmount;
                break;
              case "GBP":
                selectedAmount = convertedGbpAmount;
                break;
              default:
                selectedAmount = 0;
            }

          setResultCurrency(selectedAmount);
        } else {
          // console.log("USD rate is null");
        }
      }
    }
  }, [selectedData, rates]);

  const handleCurrencyChange = (e: React.FormEvent) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const CustomSingleValue = ({ innerProps, label, data }: any) => (
    <div
      {...innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        height: "45px",
        gap: "4px",
        position: "absolute",
      }}
    >
      <img src={data?.imgSrc} alt={label} className="h-5 w-6 my-auto ml-4" />
      {data.label}
    </div>
  );

  return (
    <section className="bg-center bg-no-repeat bg-[#054B98] bg-blend-multiply">
      <div className="hero-section">
        <div className="px-4 mx-auto max-w-screen-xl text-start py-24 lg:py-56 md:py-32">
          <h1 className="mb-4 text-4xl font-[800] tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
            A BETTER WAY
          </h1>
          <h1 className="mb-4 text-4xl font-[800] tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
            TO FLY PRIVATE
          </h1>

          <div className="flex flex-row sm:flex-row justify-start space-x-4 md:mt-14 mt-8">
            <Link
              to="/register"
              className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-[40px] bg-[#C48827] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900 max-w-[180px] max-h-[57px] md:w-[180px] md:h-[57px]"
            >
              Get started
            </Link>
            <Link
              to="/about-us"
              className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-black rounded-[40px] hover:bg-gray-100 focus:ring-4 focus:ring-gray-400 bg-[#FFFFFF] max-w-[180px] max-h-[57px] md:w-[180px] md:h-[57px]"
            >
              Learn more
            </Link>
          </div>

          {/* section two */}
          <div>
            <div className="w-full mt-40 px-4 mx-auto max-w-screen-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow-xl ">
              <form className="mt-8 space-y-6" onSubmit={handleCurrencyChange}>
                <div className="flex -mx-2 space-y-4 md:space-y-0 flex-wrap md:flex-nowrap">
                  <div className="w-full px-2 md:w-1/3">
                    <label
                      className="block mb-1 font-[700] pb-5"
                      htmlFor="formGridCode_name"
                    >
                      Amount
                    </label>
                    <input
                      className="w-full h-[45px] px-3 text-base placeholder-gray-600 border-[0.5px] rounded-lg focus:shadow-outline"
                      type="number"
                      name="amount"
                      onChange={handleAmountChange}
                      placeholder="1500"
                      autoComplete="false"
                    />
                  </div>
                  <div className="w-full px-2 md:w-1/3">
                    <label
                      className="block mb-1 font-[700] pb-5"
                      htmlFor="formGridCode_last"
                    >
                      From
                    </label>
                    <Select
                      options={options}
                      styles={customStyles}
                      name="from"
                      value={selectedOption}
                      onChange={handleSelectChange}
                      placeholder="Select Currency"
                      isSearchable={false}
                      components={{
                        Option: ({ innerProps, label, data }) => (
                          <div
                            {...innerProps}
                            className="flex flex-row px-auto mx-auto gap-8 text-[14px] mb-3 mt-2 cursor-pointer"
                          >
                            <img
                              src={data?.imgSrc}
                              alt={label}
                              className="h-5 w-6 my-auto ml-4"
                            />
                            {label}
                          </div>
                        ),
                        SingleValue: CustomSingleValue,
                      }}
                    />
                  </div>
                  <img src={plug} alt="plug" className="px-2 py-6" />
                  <div className="w-full px-2 md:w-1/3">
                    <label
                      className="block mb-1 font-[700] pb-5"
                      htmlFor="formGridCode_last"
                    >
                      To
                    </label>
                    <Select
                      options={options}
                      styles={customStyles}
                      name="to"
                      value={selectedOption}
                      onChange={handleSelectChange}
                      placeholder="Select Currency"
                      isSearchable={false}
                      components={{
                        Option: ({ innerProps, label, data }) => (
                          <div
                            {...innerProps}
                            className="flex flex-row px-auto mx-auto gap-8 text-[14px] mb-3 mt-2 cursor-pointer"
                          >
                            <img
                              src={data?.imgSrc}
                              alt={label}
                              className="h-5 w-6 my-auto ml-4"
                            />
                            {label}
                          </div>
                        ),
                        SingleValue: CustomSingleValue,
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-center md:justify-end">
                  <button
                    type="submit"
                    // disabled={disableIt}
                    onClick={() => handleCurrencyChange}
                    style={{
                      color: "white",
                      backgroundColor: "#054B98",
                    }}
                    className="text-white bg-[#054B98] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-[40px] text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 max-w-[180px] max-h-[57px] md:w-[180px] md:h-[57px] font-[700]"
                  >
                    Convert
                  </button>
                  {openModal && (
                    <ModalView
                      openModal = {openModal}
                      setOpenModal={setOpenModal}
                      resultCurrency={resultCurrency}
                      selectedAmount={selectedData.amount}
                      fromCurrency={selectedData.from}
                      toCurrency={selectedData.to}
                      rates={exchangeCurrencyRate}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
