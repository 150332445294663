import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { initFlowbite } from "flowbite";
import BaseDirectories from "../../base directories/BaseDirectories";
import { toast } from "react-toastify";
import { LogOut } from "../../utils/AuthFirestore";
import Spinner from "../../components/Spinner";
import { observer } from "mobx-react-lite";

const Sidebar = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("link1");


  useEffect(() => {
    initFlowbite();
  }, []);

  const handleItemClick = (item: string) => {
    setActiveItem(item);
  };

  const handleSignOut = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const res = await LogOut();
    if (res.error === false) {
      window.sessionStorage.clear();
      window.localStorage.clear();
      window.sessionStorage.removeItem("authUser");
      setTimeout(() => setLoading(false), 1000);
      toast.success("Logout Successful!");
      setTimeout(() => navigate("/"), 1200);
    } else {
      setLoading(false);
      toast.error("Logout Failed!");
    }
  };
  return (
    <>
      {loading && <Spinner />}
      <button
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        data-drawer-body-scrolling="false"
        data-drawer-backdrop="false"
        type="button"
        style={{
          zIndex: 50000,
        }}
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          data-drawer-hide="logo-sidebar"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
        aria-label="Sidebar"
        data-drawer-hide="logo-sidebar"
      >
        <button
          type="button"
          data-drawer-hide="logo-sidebar"
          aria-controls="logo-sidebar"
          className="text-white font-extrabold bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white md:hidden"
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        <div className="h-full px-0 py-4 overflow-y-auto bg-[#054B98] text-white dark:bg-gray-800">
          <Link
            to="/"
            className="flex items-center ps-2.5 mb-10 mt-10"
            data-drawer-hide="logo-sidebar"
          >
            <img
              src={`${BaseDirectories.LOGOS_DIR}/aeroconvert-logo-white.svg`}
              className=""
              alt="Aeroconvert Logo"
            />
          </Link>
          <ul className="space-y-10 font-medium">
            <li data-drawer-hide="logo-sidebar" className={activeItem === 'link1' ? 'active bg-[#D9D9D9] text-[#054B98]' : ''}>
              <Link
                to="/dashboard/overview"
                className="flex items-center p-2 hover:bg-[#D9D9D9] hover:text-[#054B98] group"
                onClick={() => handleItemClick('link1')}
              >
                <img
                  src={`${BaseDirectories.ICONS_DIR}/dashboard.png`}
                  alt="dashboard"
                  className="ml-3 mr-3 h-[18px] w-[18px]"
                />
                <span className="ms-3">Dashboard</span>
              </Link>
            </li>
            <li data-drawer-hide="logo-sidebar" aria-hidden="true" className={activeItem === 'link2' ? 'active bg-[#D9D9D9] text-[#054B98]' : ''}>
              <Link
                to="/dashboard/wallet"
                className="flex items-center p-2 hover:bg-[#D9D9D9] hover:text-[#054B98] group"
                onClick={() => handleItemClick('link2')}
              >
                <img
                  src={`${BaseDirectories.ICONS_DIR}/wallet.png`}
                  alt="wallet"
                  className="ml-3 mr-3 h-[18px] w-[18px]"
                />
                <span className="flex-1 ms-3 whitespace-nowrap">Wallet</span>
              </Link>
            </li>
            <li data-drawer-hide="logo-sidebar" className={activeItem === 'link3' ? 'active bg-[#D9D9D9] text-[#054B98]' : ''}>
              <Link
                to="/dashboard/transactions"
                className="flex items-center p-2 hover:bg-[#D9D9D9] hover:text-[#054B98] group"
                onClick={() => handleItemClick('link3')}
              >
                <img
                  src={`${BaseDirectories.ICONS_DIR}/transaction.png`}
                  alt="transaction"
                  className="ml-3 mr-3 h-[18px] w-[18px]"
                />
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Transactions
                </span>
              </Link>
            </li>
            <li data-drawer-hide="logo-sidebar" className={activeItem === 'link4' ? 'active bg-[#D9D9D9] text-[#054B98]' : ''}>
              <Link
                to="/dashboard/settings"
                className="flex items-center p-2 hover:bg-[#D9D9D9] hover:text-[#054B98] group"
                onClick={() => handleItemClick('link4')}
              >
                <img
                  src={`${BaseDirectories.ICONS_DIR}/settings.png`}
                  alt="settings"
                  className="ml-3 mr-3 h-[18px] w-[18px]"
                />
                <span className="flex-1 ms-3 whitespace-nowrap">Settings</span>
              </Link>
            </li>
            <li data-drawer-hide="logo-sidebar" className={activeItem === 'link5' ? 'active bg-[#D9D9D9] text-[#054B98]' : ''}>
              <button
                // to="/"
                className="flex items-center p-2 hover:bg-[#D9D9D9] hover:text-[#054B98] group w-full text-left"
                onClick={() => handleItemClick('link5')}
              >
                <img
                  src={`${BaseDirectories.ICONS_DIR}/logout.png`}
                  alt="logout"
                  className="ml-3 mr-3 h-[18px] w-[18px]"
                />
                <span
                  className="flex-1 ms-3 whitespace-nowrap"
                  onClick={handleSignOut}
                >
                  Log out
                </span>
              </button>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default observer(Sidebar);
