import React from "react";
import { Routes, Route } from 'react-router-dom';
import Overview from "../pages/dashboard/Overview";
import Wallet from "../pages/dashboard/Wallet";
import Transactions from "../pages/dashboard/Transactions";
import Settings from "../pages/dashboard/Settings";
import Error404 from "../pages/error/Error404";

const DashboardRoute = () => {
  return (
  <div>
      <Routes>
      <Route path="/overview" element={<Overview />} />
      <Route path="/wallet" element={<Wallet />} />
      <Route path="/transactions" element={<Transactions />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/*" element={<Error404 />} />
    </Routes>
  </div>
  )
}

export default DashboardRoute