/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import BaseDirectories from "../../base directories/BaseDirectories";
import "../../styles/landing_page.css";

const OurVision = () => {
  return (
    <div className="pr-4">
      {/* Our Vision */}
      <section className="bg-white">
        <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col">
          <img
            className="w-full dark:hidden order-last md:order-first"
            src={`${BaseDirectories.IMAGES_DIR}/our_vision.png`}
            alt="our vision"
          />
          <div className="mt-4 md:mt-0 order-first md:order-last">
            <h2 className="mb-4 md:text-5xl text-3xl font-[700] text-[#054B98]">
              Our Vision
            </h2>
            <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400 ">
              At the heart of our vision is the commitment to revolutionize the
              way transactions are conducted in the aviation sector. We envision
              a seamless and transparent payment ecosystem that empowers jetline
              fleet operators, lessors, manufacturers and users to streamline
              financial transactions associated with acquiring and leasing
              jetliners.
            </p>
          </div>
        </div>
      </section>
      
    </div>
  );
};

export default OurVision;
