import React from "react";
import Navbar from "../../widgets/navbar/Navbar";
import Footer from "../../widgets/footer/Footer";
import ServiceSection from "../../components/landing-page/ServiceSection";
import OurService from "../../components/landing-page/OurService";

const Services = () => {
  return (
    <div>
      <Navbar />
      <ServiceSection content="Our Services"/>
      <OurService />
      <Footer />
    </div>
  );
};

export default Services;
