import React from "react";
import ChangePassword from "../../pages/authentication/ChangePassword";

const PasswordManagement = () => {
  return (
    <section className="flex-1 flex-col md:flex-row justigy-center items-center h-[calc(100vh_-_240px)] md:w-[calc(100vw_-_450px)] text-center">
      <ChangePassword />
    </section>
  );
};

export default PasswordManagement;
