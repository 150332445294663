import axios from "axios";
import { configure, makeAutoObservable, runInAction } from "mobx";
import BaseDirectories from "../base directories/BaseDirectories";
import { toast } from "react-toastify";
configure({ enforceActions: "always" });
// eslint-disable-next-line @typescript-eslint/no-var-requires

type Profile = {
  [key: string]: any;
};

export class ProfileStore {
  loadingData = true;
  submitting = false;
  isPinExist = false;
  error = "";
  success = "";
  userId = "";

  profile: Profile = {};
  token: any = sessionStorage.getItem("accessToken") || "";
  message = {
    type: "",
    msg: "",
  };

  private userProfile!: Profile;
  constructor() {
    makeAutoObservable(this);
    runInAction(() => {
      //   this.logger = new BrowserLogger(this.constructor.name);
      this.userProfile = JSON.parse(
        sessionStorage.getItem("user") || "{}"
      ) as Profile;
    });
  }

  getProfile() {
    const headers = {
      accept: "application/json",
      Authorization: `Bearer ${this.token}`,
      "Content-Type": "application/json",
    };

    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/users/get-profile`, {
        headers,
      })
      .then((res: any) => {
        this.setProfile(res.data);
        this.setToLocalStorage("user", res.data);
        runInAction(() => (this.userId = res.data._id));
        if (res) {
          this.setToLocalStorage("isRegistered", true);
        }
      })
      .catch((err) => {})
      .finally(() => {
        this.setLoading(false);
      });
  }

  updateProfile(data: any) {
    const headers = {
      accept: "application/json",
      Authorization: `Bearer ${this.token}`,
      "Content-Type": "application/json",
    };

    this.setSubmitting(true);
    axios
      .put(`${BaseDirectories.API_BASE_URL}/users/update`, data, {
        headers,
      })
      .then((res: any) => {
        if (!res.data.error) {
          toast.success("Profile updated successfully.");
          this.setMessage("success", "Profile updated successfully");
          setTimeout(() => {
            this.setMessage("", "");
          }, 4000);
        } else {
          toast.error(`${res.data.message}`);
        }
        this.setSubmitting(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setMessage("error", err.response.data.message);
        this.setSubmitting(false);
      });
  }

  createPin(data: any) {
    const headers = {
      accept: "application/json",
      Authorization: `Bearer ${this.token}`,
      "Content-Type": "application/json",
    };

    this.setSubmitting(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/transaction-pin/create-pin`,
        data,
        {
          headers,
        }
      )
      .then((res: any) => {
        if (res.data.error) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          //   toast.success('Transfer successful!');
          this.setMessage("success", "Pin created successfully!");
        }
        setTimeout(() => {
          this.setMessage("", "");
        }, 5000);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        if (res) {
          this.setToLocalStorage("isRegistered", true);
        }
        this.setSubmitting(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setMessage("error", err.response.data.message);
        setTimeout(() => {
          this.setMessage("", "");
        }, 6000);
        this.setSubmitting(false);
      });
  }

  resetPin() {
    const headers = {
      accept: "application/json",
      Authorization: `Bearer ${this.token}`,
      "Content-Type": "application/json",
    };

    runInAction(() => {
      this.setSubmitting(true);
    });

    axios
      .get(
        `${BaseDirectories.API_BASE_URL}/transaction-pin/factory-reset-pin`,
        {
          headers,
        }
      )
      .then((res: any) => {
        if (res.data.error) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          runInAction(() => {
            this.setMessage("success", "Pin reset successfully!");
          });

          setTimeout(() => {
            runInAction(() => {
              this.setMessage("", "");
            });
          }, 5000);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);

        this.setMessage("error", err.response.data.message);

        setTimeout(() => {
          this.setMessage("", "");
        }, 6000);
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmitting(false);
        });
      });
  }

  checkPin() {
    const headers = {
      accept: "application/json",
      Authorization: `Bearer ${this.token}`,
      "Content-Type": "application/json",
    };

    runInAction(() => {
      this.setSubmitting(true);
    });

    axios
      .get(`${BaseDirectories.API_BASE_URL}/transaction-pin/check-pin`, {
        headers,
      })
      .then((res: any) => {
        if (res.data.error) {
          runInAction(() => {
            this.setPin(false);
          });
        } else {
          runInAction(() => {
            this.setPin(true);
          });
        }
      })
      .catch((err) => {
        runInAction(() => {
          this.setPin(false);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmitting(false);
        });
      });
  }

  updatePin(data: any) {
    const headers = {
      accept: "application/json",
      Authorization: `Bearer ${this.token}`,
      "Content-Type": "application/json",
    };

    this.setSubmitting(true);

    axios
      .post(`${BaseDirectories.API_BASE_URL}/transaction-pin/reset-pin`, data, {
        headers,
      })
      .then((res: any) => {
        if (res.data.error) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          runInAction(() => {
            this.setMessage("success", "Pin Update is successfully!");
          });

          setTimeout(() => {
            runInAction(() => {
              this.setMessage("", "");
            });
          }, 5000);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);

        this.setMessage("error", err.response.data.message);

        setTimeout(() => {
          this.setMessage("", "");
        }, 6000);
      })
      .finally(() => {
        runInAction(() => {
          this.setSubmitting(false);
        });
      });
  }

  setMessage = (type: string, msg: string) => {
    this.message.type = type;
    this.message.msg = msg;
  };

  setSubmitting = (val: boolean) => {
    this.submitting = val;
  };

  setLoading = (val: boolean) => {
    this.loadingData = val;
  };
  setProfile = (res: any) => {
    this.profile = res;

    // this.logger.info(`User | Get Profile | ${res.email}`, res);
  };

  setError = (err: string) => {
    this.error = err;
  };

  setSuccess = (msg: string) => {
    this.success = msg;
    // this.success_setting = msg;
  };

  setToLocalStorage = (key: string, value: any) => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  };

  SetAccessToken = (token: string) => {
    this.token = token || sessionStorage.getItem("accessToken");
  };

  setPin = (res: boolean) => {
    this.isPinExist = res;
  };
}
