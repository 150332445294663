import React, { useEffect, useState } from "react";
import BaseDirectories from "../../base directories/BaseDirectories";
import { Link, useNavigate } from "react-router-dom";
import { initFlowbite } from "flowbite";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { LogOut } from "../../utils/AuthFirestore";
import Spinner from "../../components/Spinner";

let activeItem = "link1";
const Navbar = () => {
  const [currentUser, setCurrentUser] = useState<string | null>(null);
  const [scrolling, setScrolling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMediumScreen, setIsMediumScreen] = useState(
    window.innerWidth <= 768
  );

  const navigate = useNavigate();
  // const [activeItem, setActiveItem] = useState("link1");

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  useEffect(() => {
    initFlowbite();
  }, []);

  const handleResize = () => {
    setIsMediumScreen(window.innerWidth < 768);
  };

  const handleItemClick = (item: string) => {
    // setActiveItem(item);
    activeItem = item;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentUser(window.sessionStorage.getItem("authUser") as string);
  }, [currentUser]);

  const handleSignOut = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const res = await LogOut();
    if (res.error === false) {
      window.sessionStorage.clear();
      window.localStorage.clear();
      window.sessionStorage.removeItem("authUser");
      setTimeout(() => setLoading(false), 1000);
      toast.success("Logout Successful!");
      setCurrentUser(window.sessionStorage.getItem("authUser") as string);
    } else {
      setLoading(false);
      toast.error("Logout Failed!");
    }
  };
  return (
    <nav
      className="min-h-[65px] fixed w-full z-20 top-0 start-0"
      style={{
        zIndex: 9999999,
        color: "#054B98",
        boxShadow: scrolling
          ? "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          : "0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19)",
        backgroundColor: scrolling
          ? "rgba(255, 255, 255, 1)"
          : "rgba(255, 255, 255, 1)",
      }}
    >
      {loading && <Spinner />}
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link
          to="/"
          className="flex items-center space-x-3 rtl:space-x-reverse w-32"
        >
          <img
            src={`${BaseDirectories.LOGOS_DIR}/aeroconvert-logo.svg`}
            alt="Aeroconvert Logo"
          />
        </Link>

        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          {!currentUser ? (
            <button
              type="submit"
              className="bg-[#C48827] font-mont text-[#fff] px-4 py-2 rounded-[40px] hidden md:block w-36 h-14 text-lg font-bold leading-normal capitalize text-center dark:bg-blue-600 md:w-32 md:h-12 md:text-md"
              onClick={() => navigate("/register")}
            >
              Sign Up
            </button>
          ) : (
            <button
              type="submit"
              className="bg-[#C48827] font-mont text-[#fff] px-4 py-2 rounded-[40px] hidden md:block w-36 h-14 text-lg font-bold leading-normal capitalize text-center dark:bg-blue-600 md:w-32 md:h-12 md:text-md"
              onClick={handleSignOut}
            >
              Sign Out
            </button>
          )}
          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-base rounded-lg md:hidden hover:bg-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-200 text-blue-900"
            aria-controls="navbar-sticky"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-sticky"
          style={{
            boxShadow:
              "0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <ul className="flex flex-col p-4 lg:gap-[40px] md:gap-[2px] gap-[40px] md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <Link
                onClick={() => handleItemClick("link1")}
                to="/"
                className={
                  activeItem === "link1"
                    ? "active border-b-4 border-[#C48827] text-gray-700 font-mont text-base md:text-md font-semibold normal-case pb-1"
                    : "md:text-[#fff] text-[#054B98] font-mont text-base md:text-md font-semibold normal-case border-b-0 border-[#fff] hover:border-b-4 hover:border-[#C48827] hover:text-gray-700 pb-1"
                }
                data-collapse-toggle="navbar-sticky"
                style={{
                  color: "#054B98",
                }}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                onClick={() => handleItemClick("link2")}
                to="/about-us"
                className={
                  activeItem === "link2"
                    ? "active border-b-4 border-[#C48827] text-gray-700 font-mont text-base md:text-md font-semibold normal-case pb-1"
                    : "md:text-[#fff] text-[#054B98] font-mont text-base md:text-md font-semibold normal-case border-b-0 border-[#fff] hover:border-b-4 hover:border-[#C48827] hover:text-gray-700 pb-1"
                }
                data-collapse-toggle="navbar-sticky"
                style={{
                  color: "#054B98",
                }}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                onClick={() => handleItemClick("link3")}
                to="/services"
                className={
                  activeItem === "link3"
                    ? "active border-b-4 border-[#C48827] text-gray-700 font-mont text-base md:text-md font-semibold normal-case pb-1"
                    : "md:text-[#fff] text-[#054B98] font-mont text-base md:text-md font-semibold normal-case border-b-0 border-[#fff] hover:border-b-4 hover:border-[#C48827] hover:text-gray-700 pb-1"
                }
                data-collapse-toggle="navbar-sticky"
                style={{
                  color: "#054B98",
                }}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                onClick={() => handleItemClick("link4")}
                to="/contact-us"
                className={
                  activeItem === "link4"
                    ? "active border-b-4 border-[#C48827] text-gray-700 font-mont text-base md:text-md font-semibold normal-case pb-1"
                    : "md:text-[#fff] text-[#054B98] font-mont text-base md:text-md font-semibold normal-case border-b-0 border-[#fff] hover:border-b-4 hover:border-[#C48827] hover:text-gray-700 pb-1"
                }
                data-collapse-toggle="navbar-sticky"
                style={{
                  color: "#054B98",
                }}
              >
                Contact
              </Link>
            </li>
            {!currentUser ? (
              <>
                <li>
                  <Link
                    to="/login"
                    className="md:text-[#fff] text-[#054B98] font-mont text-base md:text-md font-semibold normal-case border-b-0 border-[#fff] hover:border-b-4 hover:border-[#C48827] hover:text-gray-700 pb-1"
                    data-collapse-toggle="navbar-sticky"
                    style={{
                      color: "#054B98",
                    }}
                  >
                    Login
                  </Link>
                </li>
                <li>
                  <button
                    type="submit"
                    className="bg-[#C48827] font-mont text-[#fff] px-4 py-2 rounded-[40px] md:hidden block w-36 h-14 text-base md:text-md font-bold leading-normal capitalize text-center dark:bg-blue-600"
                    data-collapse-toggle="navbar-sticky"
                    onClick={() => navigate("/register")}
                  >
                    Sign Up
                  </button>
                </li>{" "}
              </>
            ) : (
              <>
                <li>
                  <Link
                    to="/dashboard/overview"
                    className="md:text-[#fff] text-[#054B98] font-mont text-base md:text-md font-semibold normal-case xl:ml-20"
                    style={{
                      color: "#054B98",
                    }}
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <button
                    type="submit"
                    className="bg-[#C48827] font-mont text-[#fff] px-4 py-2 rounded-[40px] md:hidden block w-36 h-14 text-base md:text-md font-bold leading-normal capitalize text-center dark:bg-blue-600 "
                    onClick={handleSignOut}
                  >
                    Sign Out
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default observer(Navbar);
