import React from "react";
import Navbar from "../../widgets/navbar/Navbar";
import Footer from "../../widgets/footer/Footer";
import AboutSection from "../../components/landing-page/AboutSection";
import OurVision from "../../components/landing-page/OurVision";

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <AboutSection />
      <OurVision />
      <Footer />
    </div>
  );
};

export default AboutUs;
