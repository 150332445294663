import React, { useContext, useEffect, useState } from "react";
import { initFlowbite } from "flowbite";
import BaseDirectories from "../../base directories/BaseDirectories";
import { StoreContext } from "../../mobx stores/RootStore";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";

const Header = () => {
  const { profileStore } = useContext(StoreContext);
  const { profile } = profileStore;
  const [username, setUsername] = useState(null);
  useEffect(() => {
    initFlowbite();
  }, []);

  useEffect(() => {
    if (profile) {
      setUsername(toJS(profile?.data)?.firstName);
    }
  }, [profile]);
  return (
    <div className="sm:ml-64 ">
      <nav className="border-gray-200 bg-[#EBEBEB] dark:bg-gray-800 dark:border-gray-700 md:h-[120px] h:auto min-h-[80px] md:grid md:content-center md:gap-0">
        <div className="max-w-screen-xl flex items-center justify-end mx-auto mr-0 lg:mx-10 p-4">
            <ul className="flex flex-row justify-evenly items-center font-medium mt-4 bg-transparent space-x-6 md:space-x-8">
              <li>
                <img
                  src={`${BaseDirectories.ICONS_DIR}/notify.png`}
                  alt="notify"
                  className="mr-3 h-[40px] w-[40px]"
                />
              </li>
              <li>
              <span className="text-lg text-[24px] text-[#054B98] font-[700]">
                  {username && `Welcome, ${username}!`}
                </span>
              </li>
              <li>
                <img
                   src={`${BaseDirectories.ICONS_DIR}/empty_user.png`}
                  alt="EmpytUser"
                  className="mr-3 h-[63px] w-[63px]"
                />
              </li>
            </ul>
        </div>
      </nav>
    </div>
  );
};

export default observer(Header);
