// PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

const PrivateRoute = ({ children }: any) => {
  const isAuthenticated = window.sessionStorage.getItem('authUser');
  return isAuthenticated !== null ? children : <Navigate to='/login' />
};
export default observer(PrivateRoute);
