import React, { useMemo, useContext, useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { StoreContext } from "../../mobx stores/RootStore";
import { toJS } from "mobx";
import { Link } from "react-router-dom";
import moment from "moment";
import Spinner from "../../components/Spinner";
import { observer } from "mobx-react-lite";

type Transaction = {
  fromCurrency: string;
  toCurrency: string;
  fromAmount: number;
  toAmount: number;
  conversionRate: number;
  debitStatus: string;
  transactionStatus: string;
  createdAt: string;
};

const Transactions = () => {
  const [token, setToken] = useState(sessionStorage.getItem("accessToken"));
  const { walletStore } = useContext(StoreContext);
  const { transactions, loadingData } = walletStore;

  useEffect(() => {
    if (token) {
      walletStore.getWallet();
      walletStore.getCurrency();
      walletStore.getAllTransactions();
    } else {
      setToken(sessionStorage.getItem("accessToken"));
    }
  }, [walletStore, token]);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "fromCurrency",
        id: "fromCurrency",
        header: "From",
        Cell: ({ cell, row }) => (
          <span>
            {row.original["fromAmount"]?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "NGN",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        ),
      },
      {
        accessorKey: "toCurrency",
        id: "toCurrency",
        header: "To",
        Cell: ({ cell, row }) => (
          <span>
            {cell.getValue<string>().toUpperCase()}{" "}
            {row.original["toAmount"]?.toLocaleString?.("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 6,
            })}
          </span>
        ),
      },
      {
        accessorKey: "conversionRate",
        id: "conversionRate",
        header: "Rate",
        Cell: ({ cell }) => (
          <>
            {cell.getValue<number>()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "NGN",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "debitStatus",
        id: "debitStatus",
        header: "Debit Status",
        Cell: ({ cell }) => (
          <span
            className={`${
              cell.getValue<string>() === "Successful"
                ? "text-[#06A77D]"
                : cell.getValue<string>() === "Failed" ? "text-red-600" :"text-orange-400"
            }`}
          >
            {cell.getValue<string>()}
          </span>
        ),
      },
      {
        accessorKey: "transactionStatus",
        id: "transactionStatus",
        header: "Trans. Status",
        Cell: ({ cell }) => (
          <span
          className={`${
            cell.getValue<string>() === "Successful"
              ? "text-[#06A77D]"
              : cell.getValue<string>() === "Failed" ? "text-red-600" :"text-orange-400"
          }`}
          >
            {cell.getValue<string>()}
          </span>
        ),
      },
      {
        accessorKey: "createdAt",
        id: "createdAt",
        header: "Time/Date",
        Cell: ({ cell }) => (
          <span>
            {moment(cell.getValue<number>()).format("hh:mm A, Do MMM, YYYY")}
          </span>
        ),
      },
    ],
    []
  );

  const theme = createTheme({
    // palette: {
    //   background: {
    //     default: "#fbf9f9",
    //   },
    //   primary: {
    //     main: "#ff9800",
    //   },
    //   secondary: {
    //     main: "#00bcd4",
    //   },
    // },
  });

  return (
    <div className="p-4 sm:ml-64">
      <div className="p-4 rounded-lg dark:border-gray-700">
        {loadingData && <Spinner />}

        {/* NEW */}
        <section className="bg-[#fbf9f9] dark:bg-gray-900 p-3 sm:p-5">
          <div className="mx-auto max-w-screen-xl px-0 lg:px-0">
            <h1 className="text-[#054B98] font-[700] lg:text-[50px] text-[30px] font-mont mb-2">
              Transactions
            </h1>
            <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
              <ThemeProvider theme={theme}>
                <MaterialReactTable
                  columns={columns}
                  data={
                    toJS(transactions) && toJS(transactions).length
                      ? toJS(transactions)
                      : []
                  }
                  defaultColumn={{
                    minSize: 20, //allow columns to get smaller than default
                    maxSize: 9001, //allow columns to get larger than default
                    size: 45, //make columns wider by default
                  }}
                  enableSorting={false}
                  enableColumnActions={false}
                  positionGlobalFilter="right"
                  enableTopToolbar={true}
                  positionToolbarAlertBanner="bottom"
                  enableRowNumbers
                  initialState={{
                    columnVisibility: { description: false },
                    density: "comfortable",
                    showGlobalFilter: true,
                    sorting: [{ id: "rowInstanceAPI", desc: false }],
                  }}
                  muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                      backgroundColor: "#fbf9f9",
                      border: "0px solid rgba(255, 255, 255,0)",
                    },
                  }}
                  muiTableProps={{
                    sx: {
                      border: "0px",
                      padding: "10px",
                      backgroundColor: "#fbf9f9",
                      boxShadow: "none",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      border: "0px",
                      backgroundColor: "#EFF0F6",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      border: "0px",
                      fontSize: "14px",
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                    },
                  }}
                  muiBottomToolbarProps={{
                    sx: {
                      border: "0px",
                      backgroundColor: "#fbf9f9",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                    },
                  }}
                  muiTopToolbarProps={{
                    sx: {
                      border: "0px",
                      backgroundColor: "#fbf9f9",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                    },
                  }}
                />
              </ThemeProvider>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default observer(Transactions);
