import React from 'react'

const NotificationsManagement = () => {
  return (
    <section className="flex-1 flex-col md:flex-row justigy-center items-center h-[calc(100vh_-_240px)] md:w-[calc(100vw_-_480px)] text-center">
    
    </section>
  )
}

export default NotificationsManagement