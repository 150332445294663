import React from "react";
import HeroSection from "../../components/landing-page/HeroSection";
import Navbar from "../../widgets/navbar/Navbar";
import Footer from "../../widgets/footer/Footer";
import MidSection from "../../components/landing-page/MidSection";

const LandingPage = () => {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <MidSection />
      <Footer />
    </div>
  );
};

export default LandingPage;
