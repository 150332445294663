// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.77);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
  }`, "",{"version":3,"sources":["webpack://./src/styles/spinner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,qCAAqC;IACrC,kCAA0B;YAA1B,0BAA0B;IAC1B,eAAe;IACf,MAAM;IACN,QAAQ;IACR,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB","sourcesContent":[".spinner {\n    height: 100vh;\n    width: 100vw;\n    background-color: rgba(0, 0, 0, 0.77);\n    backdrop-filter: blur(4px);\n    position: fixed;\n    top: 0;\n    right: 0;\n    z-index: 99999;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
